import React, { Component } from 'react'
import { Form, Input } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class StepPuase extends Component {
    state = {performed_by_pin:"",confirmed_by_pin:"",performed_by:"",performed_by_name:"",confirmed_by:"",confirmed_by_name:""}

    onInputChange = (i)=>(e, { name, value }) => {      
        console.log(name); 
        switch (name) {         
            case 'performed_by_pin':
                if( value.length === 4){
                    this.getPerformUser(value,'performer');
                }else{
                    this.setState({performed_by:"",performed_by_name:""});
                }
                break;
            case 'confirmed_by_pin':
                if( value.length === 4){
                    this.getPerformUser(value,'confirmer');
                }else{
                    this.setState({confirmed_by:"",confirmed_by_name:""});
                }
                break;
        }
            
      this.setState({ [name]: value });
      
    };
    getPerformUser = (pin,user_type)=>{
          
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }
       
        axios.post(baseURL+"/users/getUserByPin",{pin:pin}, getHeaders())
          .then(res=>{               
            if(res.data.success === true && res.data.user !== null){
                let user = res.data.user;
                if(user_type === 'performer'){                   
                    this.setState({performed_by:user.id,performed_by_name:user.full_name});
                }else{
                    this.setState({confirmed_by:user.id,confirmed_by_name:user.full_name});
                }
                 
              //this.setState({user_full_name:user.full_name}); 
            }
        })
    }

    handleSubmit = ()=>{        
        if (!this.state.performed_by_name) {
            toast.error("Please fill vaild pin.");
            return;
        }
        if (!this.state.confirmed_by_name) {
            toast.error("Please fill vaild pin.");
            return;
        }
        
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:0,
            step_json:"",
            step_name:'pause',
            step_next:'',
            status:0,
            performed_by:this.state.performed_by_pin,
            confirmed_by:this.state.confirmed_by_pin
        }       
        axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onCloseModal();
          }else{
            toast.error("Step has not saved");
          }
        })
    }

    handleCancel=()=>{
        this.props.onCloseModal();
    }

    render() {
        console.log(this.state);
        return (
            <>
             <Form onSubmit={this.handleSubmit}>
                <div className='cs-box'>
                    <div className='cs-body'>
                        <h3>Production Pause</h3>
                        <div className='mt-2'>
                            <ul className='cs-list'>
                                <li>Operator confirms the pausing of production and follows the presented steps/SOPs for halting production.</li>
                                <ul className='order-count'>
                                    <li>1. Raw material containers are sealed and returned to their appropriate storage location.</li>
                                    <li>2. All in process material is stored withing a stand-up barrier pouch, an identifier fmrx is placed on the pouch, the pouch is sealed, and placed in 4C storage until production is resumed</li>
                                    <li>3. Operator records date, and time information into BPR via personal PIN.</li>
                                </ul>
                                <li>Secondary signoff of secure pausing is confirmed via PIN.</li>
                                <li>BPR is saved in draft state within formulation software.</li>
                            </ul>
                        </div>
                        <h3>Production Resuming</h3>
                        <div className='mt-2'>
                            <ul className='cs-list'>
                                <li>Operator confirms that fmrx recorded on in process material pouch stored in 4C fridge matches with the formulation.</li>
                                <li>Operator records date, and time information into BPR via personal PIN.</li>
                                <li>Continue manufacturing from point of pause as per manufacturing procedure</li>
                            </ul>
                        </div>
                        <div className='text-center mt-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            id="performed_by_pin"
                                            name="performed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.performed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-2 mb-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            id="confirmed_by_pin"
                                            name="confirmed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.confirmed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cs-footer text-center'>
                        <hr />
                        <div className='text-center mt-2'>
                            <button type='button' onClick={this.handleCancel} className='ui icon secondary labeled button ps-1-5em'>Cancel</button>
                            <button type='submit' className='ui icon secondary labeled button pause-btn ps-1-5em ms-2' >Pause</button>
                        </div>
                    </div>
                </div>
                </Form>
            </>
        )
    }
}
export default StepPuase;