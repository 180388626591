import React, { Component } from "react";
import { Form, Popup, Input, Item, TextArea } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders,loadImage,isClinical } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class PillPackagingFlourish extends Component {
    state = { performed_by_pin:"",confirmed_by_pin:"",performed_by:"",performed_by_name:"",confirmed_by:"",confirmed_by_name:"", step_current:{},is_clinical:false,is_prebiotic:false,is_probiotic:false,formula_type:"",product_type:"",operator_notes:"",step_next:'pilling_of_mixed_prime_ingredient',sachet:[{},{},{}]};

    componentDidMount(){        
        this.getCurrentStep('pill_packaging_flourish');
        this.state.product_type = this.props.selected_row_value.product_type
        this.state.formula_type = this.props.selected_row_value.flourish_capsule_size;
        let is_clinical =   isClinical(this.props.selected_row_value.product_type);
        this.setState({is_clinical:is_clinical});
        this.state.is_probiotic = true;
        if(this.props.selected_row_value.is_prebiotic === 'Yes'){
            this.state.is_prebiotic = true;
        }else{
            this.state.is_probiotic = true;
        }
       
        if(this.props.selected_row_value.is_prime === 'No'){
            if(this.props.selected_row_value.flourish_capsule_size === "powder"){
                this.setState({step_next:"equipment_sanitization"}) 
            }
            
        }
        
    }

    getCurrentStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name : step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;       
            console.log(result);
            if(result.success === true && result.current_step !== null){
                let current_step = result.current_step;
                let step_json = JSON.parse(current_step.step_json);             
                this.setState({step_current : current_step});
                this.onInputChange(0)(null,{name:"performed_by_pin","value":current_step.performed_by});
                this.onInputChange(0)(null,{name:"confirmed_by_pin","value":current_step.confirmed_by});
                this.onInputChange(0)(null,{name:"operator_notes","value":step_json.operator_notes});
                this.state.sachet = step_json.sachet;
            }      
            
        })
    }

    onInputChange =(i)=> (e, { name, value }) => {
        switch (name) {        
            case 'performed_by_pin':
                if( value.length === 4){
                    this.getPerformUser(value,'performer');
                }else{
                    this.setState({performed_by:"",performed_by_name:""});
                }
                break;
            case 'confirmed_by_pin':
                if( value.length === 4){
                    this.getPerformUser(value,'confirmer');
                }else{
                    this.setState({confirmed_by:"",confirmed_by_name:""});
                }
                break;

            case 'measured':
                this.state.sachet[i].measured = value
                this.state.sachet[i].yield = this.getYield(value);
                    break;
            case 'primary_operator':
                this.state.sachet[i].primary_operator = value;
                    break;
            case 'secondary_signoff':
                this.state.sachet[i].secondary_signoff = value;
                    break;            
        
            default:
                break;
        }
            
      this.setState({ [name]: value });
       
    };

    getYield(value){
        return ((value/30)*100).toFixed(2)
    }

    getPerformUser = (pin,user_type)=>{
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }   
          axios.post(baseURL+"/users/getUserByPin",{pin:pin}, getHeaders())
          .then(res=>{               
            if(res.data.success === true && res.data.user !== null){
                let user = res.data.user;
                if(user_type === 'performer'){                   
                    this.setState({performed_by:user.id,performed_by_name:user.full_name});
                }else{
                    this.setState({confirmed_by:user.id,confirmed_by_name:user.full_name});
                }
                 
              //this.setState({user_full_name:user.full_name}); 
            }
        })
    }

    

    handleSubmit = ()=>{      
        let input_field = false; 

        this.state.sachet.map((value)=>{
            if(value.measured == "" || value.primary_operator == "" || value.secondary_operator == "" ){
                input_field = true;
            }else if(Object.keys(value).length == 0){
                input_field = true;
            }
        })

        if(input_field){
            toast.error("Please fill all inputs.");
            return;
        }
        if (!this.state.performed_by_name) {
            toast.error("Please fill vaild pin.");
            return;
        }
        if (!this.state.confirmed_by_name) {
            toast.error("Please fill vaild pin.");
            return;
        }
        let step_json = {operator_notes:this.state.operator_notes,sachet:this.state.sachet}

        
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:21,
            step_json:JSON.stringify(step_json),
            step_name:'pill_packaging_flourish',
            step_next:this.state.step_next,
            performed_by:this.state.performed_by_pin,
            confirmed_by:this.state.confirmed_by_pin
        }
       
        axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onNextStep();
          }else{
            toast.error("Step has not saved");
          }
        })
    }

    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('flourish_label_generation');        
    };
    handleNextClick = () => {     
        // Call the function passed from the parent
        this.props.onNextClick(this.state.step_next)        
    };
    handlePauseClick = ()=>{
        this.props.onNextClick('pause');
    }
    handleQuitClick = ()=>{
        this.props.onNextClick('quit');
    }

    render() {
        console.log(this.props);        
        console.log(this.state);       
        // this.props.selected_row_value.product_type = "monthly_flore_baby"
        return (
            <>
            <Form onSubmit={this.handleSubmit}>
                <div className='cs-box'>
                    <div className='cs-body'>
                        <h3>Pill Packaging Flourish</h3>
                        <div className='d-flex mt-2 border-b'>
                            <div className='w-50 border-r pe-3'>
                                <ul className='cs-list'>
                                    <li>Packing Pills</li>
                                    <li>Place remaining 7 Prime pills into Prime Sachet with one desiccant package. </li>
                                </ul>
                                <br/>
                                <br/>
                                <br/>
                                <ul className='cs-list'>
                                    <li>Count 30 Flourish pills, weigh. </li>
                                    <li>Confirm weight and count by initially "Weight and Count QC" field on Batch Record and QC Checklist.</li>
                                    <li>Have a secondary employee confirm weight and count.</li>
                                    <li>Secondary employee shall initial "Weight and Count QC" if correct on Batch Record and QC Checklist.</li>
                                    <li>Add denoted amount to sachet with a desiccant and heat seal.</li>
                                    <li>Correct amount is listed on the front of the sachet: 7, 23, 30 pills</li>
                                    <li>Confirm room conditions using Elemental Machines Portal.</li>
                                    <li>Initial "Room humidity" and "Room temp" on Batch Record and QC Checklist.</li>
                                </ul>
                                <br/>
                                <br/>
                                <br/>
                                <ul className='cs-list'>
                                    <li>Place 23 pill sachet in monthly Floré box with instruction card and 7 pill prime sachet if needed.</li>
                                </ul>
                                <ul className='cs-list'>
                                    <li>Confirm packaging is complete. </li>
                                    <li>Initial "Packaged for Shipment" field on Batch Record and QC Checklist.</li>
                                    <li>Have a secondary employee confirm and initial Batch Record and QC Checklist "Packaged for Shipment" field.</li>
                                </ul>
                                <br/>
                                <br/>
                                <ul className='cs-list'>
                                    <li>Place into Walk-In fridge with a blue quarantine tag or on rolling rack for filing at end of shift. </li>
                                    <li>Scan boxed product into Deli rack inventory list in shared spreadsheet.</li>
                                </ul>
                            </div>
                            <div className='w-50 ps-3'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step21/1.jpg")} alt='img' />
                                </div>
                                
                                    {
                                        (this.props.selected_row_value.product_type === "monthly_flore" || (this.props.selected_row_value.age > 12 && this.state.is_clinical )) && (
                                            <div className='mb-2'>
                                                <img className='mxw-100' src={loadImage("step21/probiotic1.jpg")} alt='img' />
                                            </div>
                                            )
                                            
                                    }
                                    {
                                            (this.props.selected_row_value.product_type === "monthly_flore_kids" || (this.props.selected_row_value.age < 12 && this.state.is_clinical )) && (
                                                <div className='mb-2'>
                                                    <img className='mxw-100' src={loadImage('step21/probiotic2.jpg')} alt='label' />
                                                </div>
                                            )
                                    }
                                    {   
                                            (this.props.selected_row_value.product_type === "monthly_tots" ) && (
                                                <div className='mb-2'>
                                                    <img className='mxw-100' src={loadImage('step21/probiotic3.jpg')} alt='label' />
                                                </div>
                                            )
                                    }
    
                                    {
                                        (this.props.selected_row_value.product_type === "monthly_flore_baby") && (
                                            <div className='mb-2'>
                                                <img className='mxw-100' src={loadImage('step21/probiotic4.jpg')} alt='label' />
                                            </div>
                                        )
                                    }
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='w-50 border-r pe-3'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <ul className='cs-list'>
                                    <li>Store rest of 5-month supply in a new bin by making a label on the 11.5” x 5.5” bin and scan into Deli inventory. Once a bin is full, repeat the process and make a new bin. </li>
                                </ul>
                            </div>
                            <div className='w-50 ps-3 pt-3'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step21/21.jpg")} alt='img' />
                                </div>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step21/22.jpg")} alt='img' />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex border-b'>
                            <div className='w-50 border-r pe-3'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <ul className='cs-list'>
                                    <li>Input the FMRX by scanning the barcode on the label for each manufactured monthly to deli inventory.</li>
                                    <li>NOTE: Filing of finished product may be done at end of shift. If this is the case, then the product shall be stored on labeled rolling rack until filing operation takes place.</li>
                                </ul>
                            </div>
                            <div className='w-50 ps-3 pt-3'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step21/24.jpg")} alt='img' />
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4>Pill Per Sachet</h4>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th>Sachet</th>
                                            <th style={{width: "20%"}}>Target Pills <br/>Per Sachet</th>
                                            <th>Measured</th>
                                            <th>Yield(%)</th>
                                            <th>Primary Operator</th>
                                            <th>Secondary Signoff</th>
                                        </tr>
                                        
                                        {
                                            this.state.sachet.map((value,key)=>{
                                            return(<tr>
                                                <td>{key+1}</td>
                                                <td>30</td>
                                                
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                        id={`measured_`+key}
                                                        name="measured"
                                                        onChange={this.onInputChange(key)}
                                                        value={value.measured?value.measured:""} 
                                                        type="text"
                                                        className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                </td>
                                                <td className='text-nowrap text-green'>{value.yield?value.yield:""}</td>
                                                <td>
                                                    <Form.Field required fluid="true">                    
                                                        <Input
                                                        id={`primary_operator`+key}
                                                        name="primary_operator"
                                                        onChange={this.onInputChange(key)}
                                                        value={value.primary_operator?value.primary_operator:""} 
                                                        type="text"
                                                        className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field> 
                                                </td>
                                                <td>
                                                        <Form.Field required fluid="true">                    
                                                            <Input
                                                            id={`secondary_signoff`+key}
                                                            name="secondary_signoff"
                                                            onChange={this.onInputChange(key)}
                                                            type="text"
                                                            value={value.secondary_signoff?value.secondary_signoff:""} 
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                </td>
                                            </tr>)
                                            })
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='mt-5'>
                            <div><h4 className='m-0'>Operator Notes:</h4></div>
                            <div>
                            <Form.Field required fluid="true">                    
                                <TextArea
                                id="operator_notes"
                                name="operator_notes"
                                onChange={this.onInputChange(0)}
                                value={this.state.operator_notes}
                                placeholder="Operator Notes"
                                className="notes white-textarea text-black"
                                />
                            </Form.Field>
                              
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0 text-center mb-1'>Statement Of Deviation Requirements:</h4></div>
                            <div><h5 className='m-0 text-center fw-500'>If any process yields fall outside of stated range, conditional step "3C. Process Deviation" is to be conducted.</h5></div>
                        </div>
                        <div className='text-center mt-5'>
                            <div className="d-inline-flex">
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            id="performed_by_pin"
                                            name="performed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.performed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-2 mb-5'>
                            <div className="d-inline-flex">
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            id="confirmed_by_pin"
                                            name="confirmed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.confirmed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cs-footer text-center'>
                        <hr />
                        <div className='text-center mt-2'>
                        <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                        <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                        <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                        { this.props.step_name === 'flourish_label_generation' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                        { this.props.step_name !== "flourish_label_generation" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}                        
                    </div>
                    </div>
                </div>
            </Form>
            </>
        )
    }
}

export default PillPackagingFlourish;