import Config from "../../configuration/config";
import { customPrint, getHeaders } from "../helper";
import { baseURL, PRINT_CATEGORIES, portalURL, FormulationPortalDev, reportFormulationUrl } from "../../configuration/constants";
import CustomDivider from "../custom_elements/CustomDivider";
import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Form, Input, Grid, TextArea, Modal, Label, Segment, Popup, Button, Icon, Accordion, Header, Message } from "semantic-ui-react";
import { onGridReady } from "../helper";
import { AgGridReact } from "ag-grid-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewReformulationForm from './Reformulation/newReformulationForm';
import ReformulationNotes from './ReformulationNotes';
import './index.css';
import { PastFormulations } from "../formulation_rationale/NewPastFormulation";
import CustomerProfileNotesReformulation from "./CustomerProfileNotesReformulation";
import CustomLoader from "../custom_elements/CustomLoader";
import CustomConfirm from "../custom_elements/CustomConfirm";


export { selectedSample, selectedFormula, sampleData, rowSelectValue };
export { selectedProduct };

var reformulation_logs = [];
var reformulation_logs_exists = null;
let api, column_api, selectedSample, selectedProduct, selectedFormula, sampleData, rowSelectValue;
let typeParams = "";
let lowerCase = "";


let profile_notes = [];
let notes = [];
export let reformulation_details = [];
var timepoint_listing = [];



class ReFormulationInfoRefill extends Component {
  is_mounted = false;
  state = {
    deletePermission: true,
    sample_id: "",
    newTableId: "",
    is_confirm_open: false,
    samples: [],
    activeIndexs: [0],
    deleteMsg: "",
    next_sample_ids_json: [],
    formula_ids_json: [],
    openModel: false,
    reformulation_notes: " ",
    survey: [],
    reformulation:[],
    setFormHeight: "",
    showPastFormula: false,
    timepointList: [],
    columnDefs:
      [
        {
          headerName: "Sample ID",
          field: "sample_id",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "Open Report",
          field: "report_id",
          width: 80,
          cellRenderer: (params) => {
            let x = `${reportFormulationUrl}/${params.data.sample_id}`
            let target = "_blank"
            return (
              `<a href=${x} target=${target} >
              <i class="fa fa-external-link" aria-hidden="true"></i>
             </a>
              `
            )
          }
        },
        {
          headerName: "Formula ID",
          field: "formula_id",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true

        },
        {
          headerName: "Added Date",
          field: "date_created",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true

        },
        {
          headerName: "Date Made",
          field: "date_made",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "Product Type",
          field: "product_type",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "Reformulation Source",
          field: "reformulation_request_source",
          width: 250,
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "Type",
          field: "type",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true,
          cellRenderer: (params) => {
            if (params.value === "refill_queue") {
              return (
                `
                    <div>
                    Refill
                    </div>
                    `
              )
            }
            else {
              return (
                `
                    <div>
                    ${params.value.charAt(0).toUpperCase() + params.value.slice(1)}
                    </div>
                    `
              )
            }
          }
        },
      ]

  };

  componentDidMount() {

    this.getSamples();
    this.is_mounted = true;

    customPrint(
      Config.formulations.section_title.create + " <FormulationInfo>",
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/formulations/formulaIds"
    );
    axios
      .get(baseURL + "/formulations/formulaIds", getHeaders())
      .then(response => {
        customPrint(
          Config.formulations.section_title.create + "<FormulationInfo>",
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        let data = response.data;

        let formula_ids_json = [];
        if (typeof data === "object") {
          data.forEach(elem => {
            formula_ids_json.push({ title: elem });
          });
          if (this.is_mounted) {
            this.setState({
              formula_ids_json,
              is_page_loading: false
            });

          }
        }
      })
      .catch(error => {
        alert("Fetching Formula ID | Error: " + error);
        this.setState({ is_page_loading: false })
        this.props.changeLoadingStateValue(false);    
       });
  }
  getSamples = () => {
    axios.get(baseURL + "/formulations/refill-queue", getHeaders())
      .then(res => {
        let data = res.data;
        return data;
      })
      .then(samples => {

        if (samples.length > 0) {
          this.setState({
            samples: samples[0],
            newTableId: samples.id
          });
        }
      });
  };

  handleSelectedSample = (sample) => {
    timepoint_listing = [];
    this.props.loader_on_select_sample(true);
    this.props.isPageLoading(true);
    this.GetHealthAndDietSurvey(sample.data.sample_id);
    this.setState({ deletePermission: false, setFormHeight: "", pastFormulationSampleId: sample.data.sample_id, showPastFormula: false, timepointList: [] })
    axios.post(portalURL + "/getTimePoint", { token: Config.token, sg_number: sample.data.sample_id })
      .then((result) => {
        if (result.data.data !== null && (result.data.data.length>0 || Object.keys(result.data.data).length>0)) {
          timepoint_listing = result.data.data.timepoint_list;
          this.setState({ timepointList: result.data.data.timepoint_list });
        }
        else {
          this.props.isPageLoading(false);
        }
      })
      
    if (sample.colDef.headerName === "Sample ID"
    || sample.colDef.headerName === "Date Created"
    || sample.colDef.headerName === "Date Made"
    || sample.colDef.headerName === "Product Type"
    || sample.colDef.headerName === "Type") {
      this.wepPortalApi(sample.data.sample_id)
      
      if(timepoint_listing.length>0) { this.getCustomerProfileNotes(timepoint_listing); }

      this.setState({ sample_id: sample.data.sample_id });

      this.props.reformulationId(sample.data.id)
      axios.get(baseURL + "/getrefillQueueData/" + sample.data.id + "?type=refill_queue", getHeaders())
        .then(res => {

          // check reformaulation logs exists or not 
          if (res.data[0].reformulation_logs_exist !== null) {

            reformulation_logs = res.data[0].reformulation_logs
            reformulation_logs_exists = res.data[0].reformulation_logs_exist
          }
          else {
            reformulation_logs_exists = null;
            reformulation_logs = [];
          }
          
          this.props.handlerAssign(res.data[0])
        })

      let formulaIds;
      let reTest = sample.data;
      // console.log(sample.data);
      selectedSample = reTest["sample_id"];
      selectedProduct = reTest["product_type"]
      
      this.props.handlerProductType(selectedProduct,sample.data.reformulation_request_source)
      var getFormulaLastPart = reTest["formula_id"].split("_");

      let increment = parseInt(getFormulaLastPart[2]) + 1;
      let number = String(increment).padStart(3, '0')
      let newFormulaId = getFormulaLastPart[0] + "_" + getFormulaLastPart[1] + "_" + number;


      axios
        .get(baseURL + "/formulations/getRefillRecordsforFormulaId", getHeaders())
        .then(result => {
          formulaIds = result.data;
          newFormulaId = this.createAutoIncFormula(formulaIds, newFormulaId);
          selectedFormula = newFormulaId;
          this.props.changeLoadingStateValue(false);          
          this.props.isPageLoading(false);          
          this.setState({ sample_id: reTest["sg_number"], formula_id: newFormulaId });
        }).catch((err)=>{
              this.props.changeLoadingStateValue(false);          
              this.props.isPageLoading(false);          
        })
      return selectedSample;
    }
    else {
      if (sample.colDef.headerName === "View Details") {
        axios.get(baseURL + "/refomulation-logs/" + sample.data.id, getHeaders())
          .then(res => {
            if (res.data.data.length == 0) { toast.info('Data Empty'); return; } })
          .catch(err => {
            this.props.changeLoadingStateValue(false);
            toast.error(err.message);
          })
      }
    }

  }

  wepPortalApi = (value) => {
    let data = {
      sg_number: value,
      token: Config.token
    }
    axios
      .post(portalURL + "/getKitProductType", data)
      .then(response => {
        this.props.flore_probiotics(response.data.data.flore_probiotics)
      })
  }

  createAutoIncFormula(formulaIds, newFormulaId) {
    for (var i = 0; i < formulaIds.length; i++) {
      formulaIds.forEach(res => {
        if (res.formula_id === newFormulaId) {
          var getFormulaLastPart = newFormulaId.split("_");
          let increment = parseInt(getFormulaLastPart[2]) + 1;
          let number = String(increment).padStart(3, '0')
          newFormulaId = getFormulaLastPart[0] + "_" + getFormulaLastPart[1] + "_" + number;
        }
      })
    }
    this.props.loader_on_select_sample(false);

    return newFormulaId;

  }
  componentWillUnmount() {

    this.is_mounted = false;
  }
  handleOpen = (e) => {
    this.setState({ modalOpen: true })
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  closeModal = () => {
    this.setState({ openModel: false })
  }

  handleChange = (e) => [
    this.props.handlerPayementType(e.target.value)
  ]
  reformulation_notes = (e) => { this.props.reformulation_notes(e); }

  pastFormulation = () => {
    this.setState({ showPastFormula: true });
    this.props.isPageLoading(true);
  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndexs } = this.state;

    const newIndex = [];

    const currentIndexPosition = activeIndexs.indexOf(index);
    var x = false;
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
      x = true;
    }
    if (!x) {
      newIndex.push(index);
    }
    this.setState({ activeIndexs: newIndex });
  };

  GetHealthAndDietSurvey = (selectedSample) => {
    this.props.isPageLoading(true);
    let payload = { sg_number: selectedSample, token: Config.token};
    axios.post(`${portalURL}/getHealthDietSurvey`, payload).then(res => {
      if (res.data.data == [] || res.data.data == "") {
        this.setState({
          survey: [],
        })
      }
      else {
        let surveyData = res.data.data;
        if (surveyData.length > 0) {
          this.setState(
            {
              survey: surveyData,
              setFormHeight: "reformulation"
            },
          );
          this.props.isPageLoading(false);
        }
      }
    });
  }
  generateSurveyText = (activeIndexs) => {
    return (
      <div>
        {this.state.survey.length > 0 && this.state.survey.map((item, index) => {
          return <Accordion>
            <Accordion.Title
              active={activeIndexs.includes(0)}
              index={index}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              {item.sg_number}
            </Accordion.Title>
            <Accordion.Content active={activeIndexs.includes(index)}>
              <ol style={{ marginTop: "-3px" }}>

                {item.survey_data.map((item, index) => (
                  <li
                    key={index}
                    className="formulation-rationale__health-survey__item"
                  >
                    <b>{item.headings[0].heading}</b>
                    {item.family === "open_ended" ? (
                      <p>{item.answers}</p>
                    ) : (item.family === "matrix" ? (
                      <div>
                        {item.answers.map((answer, index) => (
                          <div key={index}>
                            <p>{answer.choicesVal}</p>
                          </div>
                        ))}
                      </div>
                    ) : (<div> {item.answers.map((answer, index) => (<div key={index}> <p>{answer.text}</p> </div>))}
                    </div>))}
                  </li>
                ))}
              </ol>
            </Accordion.Content>
          </Accordion>
        })}

      </div>
    );
  };
  getCustomerProfileNotes = (timepointList) => {
    var sg_number_list = [];

    timepointList && timepointList.map((list_sg_number) => {
      sg_number_list.push(list_sg_number.sg_number);
    })

    this.props.isPageLoading(true);
    axios
      .get(`${baseURL}/formulations/getAllNotes?sg_number=${sg_number_list}`, getHeaders())
      .then(res => {
        profile_notes = res.data.customer_notes;
        notes = res.data.notes;
        reformulation_details = res.data.reformulation_details;
        if(reformulation_details.length>0 && !Array.isArray(reformulation_logs)){
          var reformulation_info  = { sample_id:reformulation_logs.sample_id }
          delete reformulation_logs.sample_id;
          reformulation_info.data = reformulation_logs
          reformulation_details.push(reformulation_info);
          reformulation_logs = reformulation_details;
        }
        this.props.isPageLoading(false);
        this.setState({ setFormHeight: "reformulation"})
      }).catch((err) => {
        this.props.isPageLoading(false); });

  }
  set_page_loading = (value) => {
    // this.setState({ is_page_loading: value })
    this.props.isPageLoading(value);
    setTimeout(() => {
      window.scrollTo({
        top: 700,
        behavior: 'smooth',
      })
    }, 500);

  }
  changeStatus(value) {
    this.props.isPageLoading(value);
  }
  exportCSV = () => {
    this.props.isPageLoading(true);

    axios
      .get(baseURL + "/generate_csv_create_reformulation", getHeaders())
      .then(response => {
        if (response.data.success === true) {
          window.open(response.data.url, '_blank');
          this.props.isPageLoading(false);
        }
        this.props.isPageLoading(false);
      })
      .catch(error => {
        this.props.isPageLoading(false);
        
      });
  }

  onDeleteClick = () => {
    let selectedData = api.getSelectedRows();
    selectedData.map(selectedRow => {
      this.setState({
        deleteMsg: " Are you sure you want to delete the formula " + selectedRow.formula_id + " associated with sample " + selectedRow.sample_id + " ?"
      })
    })
    this.setState({ is_confirm_open: true });
  }
  // Handles 'confirm delete' click.
  onConfirm = () => {
    this.setState({ is_confirm_open: false });
    let selectedData = api.getSelectedRows();
    let ids = [];
    let sampleId;
    ids = "" + selectedData.map(record => record.id);
    sampleId = selectedData.map(record => record.sample_id);
    sampleId.filter((elem => {
      typeParams = elem.split("_", 1);
    }))
    for (let i = 0; i < typeParams.length; i++) {
      let axis = typeParams[i];
      lowerCase = axis.toLowerCase();
    }
    axios
      .get(
        baseURL +
        "/delete_formula/" +
        ids.toString() + "?type=" + lowerCase,
        getHeaders()
      )
      .then(response => {
        alert(response.data.message);
        const interval = setInterval(() => {
          window.location.reload(false);
        }
          , 1000);

      })
      .catch(error => {
        alert("Deleting from Display Grid | Error: ", error);
        this.props.changeLoadingStateValue(false);
      });


    api.updateRowData({ remove: selectedData });
  };
  onCancel = () => {
    this.setState({ is_confirm_open: false });
  };

  rowStyle = (params) => {
    if (params.data.reformulation_request_source == "Customer") {
      return { background: "yellow" };
    }
  };


  render() {

    let sgnumber = "";
    sgnumber = this.props.sample_id;
    if (sgnumber == null) {
      sgnumber = "";
    }
    const { activeIndexs } = this.state;
    let surveyText = this.generateSurveyText(activeIndexs);

    return (
      <Grid columns="equal">

        <CustomConfirm
          is_confirm_open={this.state.is_confirm_open}
          content={this.state.deleteMsg}
          header="Delete selected Record(s)"
          cancel_button_text="Eh! Never Mind."
          confirm_button_text="DELETE"
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}

        />

        <CustomDivider
          h_size="h3"
          title="Formula Information"
          icon_name="calculator"
        />
        <Button
          basic
          onClick={() => this.pastFormulation()}
          icon
          labelPosition="right"
          disabled={timepoint_listing.length == 0}
          style={{ marginLeft: "15px" }}
        >
          Previous Formulation
          <Icon name="eye" />
        </Button>
        &nbsp; &nbsp; &nbsp;
        <Button
          basic
          onClick={this.exportCSV}
          icon
          labelPosition="left"
        >
          Export as CSV
          <Icon name="filter" />
        </Button>
        &nbsp; &nbsp; &nbsp;

        <Button
          basic
          className="ml-auto mr-15"
          negative
          onClick={this.onDeleteClick}
          icon
          labelPosition="right"
          disabled={this.state.deletePermission}
        >
          Delete Record(s)
          <Icon name="trash" />
        </Button>

        <Grid.Row>
          <Grid.Column>
            <Segment
              compact
              raised
              padded
              style={{ display: "block", marginTop: "0" }}
            >
              <Grid columns="equal">
                <Grid.Row>
                  <Grid.Column>
                    <div
                      id="myGrid"
                      className="ag-theme-balham"
                      ag-grid="gridOptions"
                      style={{
                        height: "100%",
                        width: "100%"
                      }}
                    >
                      <AgGridReact
                        rowHeight="30"
                        rowSelection="single"
                        onCellClicked={e => {
                          // let sample = e.data;
                          let sample = e;
                          this.handleSelectedSample(sample);
                        }}
                        // onCellClicked={this.openPopup}
                        onGridReady={params => {
                          api = params.api;
                          api.showLoadingOverlay();
                          api.sizeColumnsToFit();
                          column_api = params.column_api;
                          onGridReady(
                            params,
                            api,
                            column_api,
                            this.props.initial_sort
                          );
                        }}
                        getRowStyle={this.rowStyle}
                        animateRows={true}
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={params => {
                          return "[" + params.value.toLocaleString() + "]";
                        }}

                        columnDefs={this.state.columnDefs}
                        floatingFilter={true}
                        rowData={this.state.samples}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {this.state.showPastFormula &&
          <PastFormulations
            ReformulationInfo={true}
            sg_number={this.state.pastFormulationSampleId}
            selectedSample={this.state.timepointList}
            set_page_loading={this.set_page_loading}
            getTagDataFromParent={this.props.getTagDataFromParent}
            changeStatus={this.changeStatus}
          />
        }
        <Grid.Row>
          <Grid.Column>
            <NewReformulationForm
              reformulation_logs={reformulation_logs}
              reformulation_details={reformulation_details}
              reformulation_logs_exists={reformulation_logs_exists}
              component={this.state.setFormHeight}
            />

            {(this.state.setFormHeight !== "") && (
              <>
                <Message
                  className="formulation-rationale__health-survey__header"
                  style={{ marginBottom: "0" }}
                >
                  <Header as="h2" textAlign="center">
                    Health Survey
                  </Header>
                </Message>
                <Segment
                  style={{
                    marginTop: "0",
                    marginBottom: "0",
                  }} className="formulation-rationale__health-survey"
                >
                  {surveyText}
                </Segment>
              </>

            )
            }

          </Grid.Column>
          <Grid.Column>
            <ReformulationNotes
              reformulation_notes={this.reformulation_notes}
              component={this.state.setFormHeight}
            />
            {(this.state.setFormHeight !== "") && (
              <div>
                <br />
                <CustomerProfileNotesReformulation
                  profile_notes={profile_notes}
                  notes={notes}
                  refill={false}
                />
              </div>
            )}

          </Grid.Column>
        </Grid.Row>



        <Grid.Row>
          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Sample ID </label>}
                content="Enter the Sample ID."
              />
              <Input
                id="sample_id"
                name="sample_id"
                onChange={this.props.onChange}
                value={selectedSample ? selectedSample : sgnumber}
                disabled={this.props.disabledPermission}
                placeholder="Sample ID"
              // disabled={selectedSample ? true : false}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field fluid="true">
              <Popup
                trigger={<label>Product Type</label>}
                content="Enter the Product Type."
              />
              <Input
                value={selectedProduct}
                placeholder="Product Type"
                disabled={selectedProduct ? true : false}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Date Created</label>}
                content="Enter Date Created."
              />
              <DatePicker
                selected={this.props.date_created}
                placeholderText="Click to select the Date Created"
                todayButton={"Current Date"}
                dateFormat="MM/dd/yyyy"
                isClearable={true}
                disabled={this.props.disabledPermission}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                openToDate={new Date(Config.default_calendar_date)}
                onChange={date => {
                  this.props.onDateChange(
                    Config.formulations.date_created,
                    date
                  );
                }}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Number of Months</label>}
                content="Enter the Number of Months."
              />
              <Input
                name="number_of_months"
                type="number"
                onChange={this.props.onChange}
                value={this.props.number_of_months}
                icon="calendar alternate outline"
                iconPosition="left"
                placeholder="Number of Months"
                disabled={this.props.disabledPermission}
              />
              {this.props.form_errors.number_of_months && (
                <Label basic color="red" pointing>
                  {this.props.form_errors.number_of_months}
                </Label>
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field fluid="true">
              <Popup
                trigger={<label>Payment</label>}
                content="Enter the Payment."
              />
              <select onChange={this.handleChange}
                value={this.props.payment_type}
              >
                {
                  this.props.payementOptions && this.props.payementOptions.map((elem, i) => {
                    return (
                      <option key={i}>
                        {elem.payment_type}
                      </option>

                    )
                  })
                }
              </select>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Popup
              trigger={<label>Admin Notes</label>}
              content="Enter Admin notes."
            />
            <TextArea
              id="admin_notes"
              name="admin_notes"
              onChange={this.props.onChange}
              value={this.props.admin_notes}
              placeholder="Add Notes here..."
              style={{ minHeight: 100 }}
              disabled={this.props.disabledPermission}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>

    );
  }
}

export default ReFormulationInfoRefill;
