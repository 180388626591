import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";

import "react-datepicker/dist/react-datepicker.css";
import { Form, Segment, Grid, Message, Header } from "semantic-ui-react";

import Samples from "./samples";

import Tags from "./Tags/";
import HealthSummary from "./HealthSummary";
import ClinicalInfo from "./ClinicalInfo"
import CustomerProfileNotes from "./CustomerProfileNotes/CustomerProfileNotes";
import "./index.css";
import Footer from "./Footer";

import SampleDataFromTable from "./samples/SampleDataFromTable";
import "./index.css";
import CustomDimmer from "../custom_elements/CustomDimmer";
import CustomLoader from "../custom_elements/CustomLoader";
import SuccessMessage from "../custom_elements/SuccessMessage";
import ErrorMessage from "../custom_elements/ErrorMessage";
import ShowModal from "../Pop Up/Modal";

import { focusFirstElement, getDateString, customPrint, convertReqdInventoryOptionsJSON, getHeaders,
         getSessionStoredJsonItem, isFormValid, getUserRole, simplifyModulePermissionData, digitsAfterPointOrganism,
         digitsAfterPrimeFlourish, prime_filler_amount_added, product_type_array, floatOption, Product_Type, validation_product_type,clinical_validation_product_type,
         product_type_probiotics_validation, month_wise_validation, product_type_3_month,Clinical_Prebiotic, product_type_six_twelve_validation, probiotic_product_type, six_twelve_month_wise_validation } from "../helper";
         
import { baseURL, PRINT_CATEGORIES, portalURL } from "../../configuration/constants";
import Config from "../../configuration/config";
import Prime from "../../components/formulation_mod/Prime";
import FlourishFormulation from "./createFormulationRational/FlourishFormulation";
import { getPrimeTotalAmountAdded, getFormulationFactor, getFinalAmountAdded, getTotalFinalAmountAdded, getTotalCFU, getFinalConcentration, getScrapAmount, getTotalFinalConcentration, getScrap, getFormulationFactorForClinicalFormula, getFormulationFactorForClinicalFormulaFor12Months } from "../../components/formulation_mod/Formulas";
import TotalInfo from "../../components/formulation_mod/TotalInfo";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PastFormulations } from "./NewPastFormulation";
import { InfluencedMicrobes } from "./influncedMicrobes";
let inventoryArr = [],
  count = 0;
let type;
let tempArray_tag = [];
let obj_tag = {};
let handle_total_cfu = 0;
let temprary_value = 0;
let arr = [];
let formulation_factor = 0;
const options = [{ value: 0 }, { value: 1 }, { value: "powder" }];

let warning = "";
// let is_popUp_disabled = false;
let popUp_description = "";
let prime_json = [];

let prime_section_arr = {};
let remianing_amount = 0;
const floatOptions = floatOption;

let total_amount_added = 0;
let submit_event = null;
var ingredientAdded = false;

export class FormulationRationale extends Component {
  is_mounted = true;
  past_formulation;

  constructor(props) {
    super(props);
    localStorage.removeItem("setSession");
    this.multiselectRef = React.createRef();
    this.userInputArr = [];
  }

  // STATE INFO
  getInitState = () => {
    return {
      allergen_info:[],
      is_prime_added_disabled: false,
      is_number_of_month_disabled: false,
      h_d_survey_height: "500px",
      pastFormulation: false,
      Selected_Sample: {},
      is_popUp_disabled: false,
      popUp_description: "",
      warning: "",
      id_partitaly_value: 0,
      prev_amount_added: 0,
      prev_total_cfu: 0,
      prev_total_amount_added: 0,
      florish_section_flag: false,
      beforeInsertCfu: 0,
      dataUp: [],
      dataUp_amountAdded: 0,
      dataUp_final_amount_added: 0,
      dataUp_gain: 0,
      check_insert: 0,
      check: 0,
      userInputArrPush: "",
      formulation_check: 1,
      alltype: "all",
      gain: 0,
      TotalCfu: 0,
      selectedItem: [],
      multiSelectData: [],
      selectedSample: {},
      selectedAilments: "",
      ailmentAnswer: "",
      tags: [],
      uponValue: "",
      is_saving_successful: false,
      is_saving_failed: false,
      new_save: false,
      new_product: false,
      is_waiting_for_response: false,
      is_message_visible: true,
      is_page_loading: true,
      response_message: "",
      sample_id_message: "",
      Check_formula_value: null,
      sample_id: "",
      formula_id: "",
      date_created: new Date(),
      lot: 0,
      formulation_factor: 1,
      number_of_months: 3,
      sgNumber: "",
      inventoryArr: [],
      prime_filler_inventory_id: null,
      prime_flourish_amount_added: 1.2,
      prime_filler_amount_added: 4.8,
      prime_total_amount_added: 6,
      prime_filler_scrap_amount: 0,
      rowData: [],
      prime_capsule_size: 0,
      is_prime_added: true,
      reformulate: false,
      createFormulationpermission: 0,
      createFormulation: 0,
      disabledPermission: "",
      flourish_capsule_size: 0,
      product_type: "",
      admin_notes: "",
      delete_scrap_amount: false,
      organisms: [],
      inventory_id: 0,
      organism_barcode: "",
      amount_added: "",
      concentration: 0,
      final_concentration: 0,
      scrap_amount: 0,
      final_amount_added: 0,
      total_cfu: 0,
      is_amount_added_exceeding_qty: false,
      is_prime_filler_amount_added_exceeding_qty: false,
      is_organism_already_added: false,
      is_clinical:false,
      prev_sg_number: "",
      sg_numbers: "",
      id: "",
      pendding_amount_added: 0,
      pendding_total_cfu: 0,
      formula_type : "pro",
      //total info state(s)
      total_amount_added: 0,
      total_final_concentration: 0,
      total_final_amount_added: 0,

      organism_barcode_multiselectedData: [],
      influenced_microbes: [],
      clinical_info: {organism_interest_concern:"",pertinent_clinical_info:"",additional_notes:""},
      influenced_microbes_heading: [],

      inventory_options: [],
      inventory_options_prime: [],
      reqd_inventory_fields_json: [],
      converted_reqd_inventory_fields_json: [],
      form_errors: {
        sample_id: "",
        formula_id: "",
        number_of_months: "",
        prime_filler_inventory_id: "",
      },
    };
  };

  state = this.getInitState();

  componentDidMount() {
    // document.title = 'Formulation Rationale | Probiotic Formulations';
    var numberOfMonths;
    var formula_type = "pro";
    document.title = Config.formulations.title.create;
    let loginSucess = localStorage.getItem("login_success");
    if (loginSucess == 1) {
      this.getUserPermission();
    }
    focusFirstElement();

    this.is_mounted = true;

    axios.get(baseURL + "/formulationsOperator", getHeaders()).then((response) => {
      let rowData = null;

      rowData = response.data.filter((f) => {
        if (f.is_completed === 1) return false;
        if (f.type === "refill") return false;
        let flag = true;

        return flag;
      });
      const queryParameters = new URLSearchParams(window.location.search)
      let sample_id = queryParameters.get("sample_id");    
      if(sample_id !== null && sample_id !== ""){
        numberOfMonths = 3;
        formula_type = "pre";
      }
      this.setState({
        rowData: rowData,
        formula_type:formula_type
      });
    });
    this.fetchDataPrime();
    this.reuseFetchApi("pre,pro,for");
  }

  change = (value) => {
    this.setState({ flourish_capsule_size: value });

    var months = value === "powder" ? 3 : Number(this.state.number_of_months);
    formulation_factor = months / 3;


    this.setState({
      number_of_months: months,
      formulation_factor: months / 3,
    });

    // calculate value
    this.calculatevalue([...this.state.organisms]);

    if (this.state.is_prime_added == true) {
      this.setState({
        prime_capsule_size: value,
      });
    } else {
      this.setState({
        prime_capsule_size: "",
      });
    }
  };
  onSelect = (selectedList, selectedItem) => {
    if (selectedList && selectedList.length) {
      this.setState({ tags: selectedList });
    } else {
      this.setState({ tags: [] });
    }
  };

  changeStructureFunction = (value) => {
    var arr3 = [];
    for (var i = 0; i < this.state.organisms.length; i++) {
      arr3.push(this.state.organisms[i]);
    }
    for (var i = 0; i < this.state.dataUp.length; i++) {
      arr3.push(this.state.dataUp[i]);
    }

    if (arr3.length == 0) {
      this.userInputArr = [];
      this.reuseFetchApi("pre,pro,for");
    } else if (type === "FOR") {
      this.insert("for", this.userInputArr);
    } else {
      this.insert("pre,pro", this.userInputArr);
    }

    this.onInputChange(value, {
      name: this.state.organism_barcode,
      value: this.state.inventory_id,
    });
  };

  changePrimeAddition = () => {
    let is_prime_added = this.state.is_prime_added === true ? false : true;

    // Changes the 'prime_filler_inventory_id' to null
    // so fetching the prime data, usage of prime can be detected.
    let form_errors = { ...this.state.form_errors };
    if (this.state.is_prime_added == false && this.state.prime_filler_inventory_id == null) {
      form_errors.prime_filler_inventory_id = "Please select an organism";
      this.setState({ form_errors });
    } else {
      form_errors.prime_filler_inventory_id = "";
      this.setState({ form_errors });
    }
    let prime_filler_inventory_id = is_prime_added ? this.state.prime_filler_inventory_id : null;
    let prime_flourish_amount_added = is_prime_added ? 1.2 : 0;
    let prime_filler_amount_added = is_prime_added ? 4.8 : 0;
    let prime_total_amount_added = is_prime_added ? 6 : 0;

    /*  start Task:- https://app.asana.com/0/1127420590190489/1180993390916679/f */
    // prime_section_arr.value = prime_filler_amount_added;
    /*  end */
    this.setState({
      is_prime_added,
      prime_filler_inventory_id,
      prime_flourish_amount_added,
      prime_filler_amount_added,
      prime_filler_scrap_amount: 0,
      prime_total_amount_added,
    });
  };

  changeReformulation = () => {
    let reformulate = this.state.reformulate === true ? true : false;
    this.setState({
      reformulate: reformulate,
    });
  };

  changeInputReformulation = (value) => {
    this.setState({ reformulate: value });
  };

  flore_probiotics = (value) => {
    let powder = "powder";
    if (value === "powder") {
      this.setState({
        flourish_capsule_size: powder,
        prime_capsule_size: powder,
      });
    } else {
      this.setState({
        flourish_capsule_size: 0,
        prime_capsule_size: 0,
      });
    }
  };
  // call on input change
  onInputChange = (e, { name, value }) => {
    this.setState({
      uponValue: value,
    });

    let form_errors = { ...this.state.form_errors };

    switch (name) {
      // Validation check.
      case "sample_id": {
        value = value.toUpperCase();

        form_errors.sample_id = "";
        this.setState({ form_errors: form_errors });
        this.state.rowData.forEach((details) => {
          details.sample_id = details.sample_id.toUpperCase();
          if (details.sample_id == value) {
            form_errors.sample_id = `Already exists in formulation queue
               page either take another sg number
               or make formulation of this sgnumber
               in formulation page`;
            this.setState({ form_errors: form_errors });
          }
        });

        break;
      }

      case "prime_flourish_amount_added": {
        digitsAfterPrimeFlourish();

        this.setState({
          prime_total_amount_added: getPrimeTotalAmountAdded(value, this.state.prime_filler_amount_added),
        });
        break;
      }

      case "prime_filler_amount_added": {
        //if the prime filler organism is not added yet, return.
        prime_filler_amount_added();

        if (this.state.inventory_id !== "" && this.state.inventory_id == this.state.prime_filler_inventory_id) {
          this.setState({ amount_added: 0 });
        }
        if (this.state.prime_filler_inventory_id === null) {
          alert("Please enter Prime Filler Organism first");
          return;
        }
        if (prime_json[this.state.prime_filler_inventory_id] !== undefined && this.state.prime_filler_inventory_id !== undefined) {
          // quantity
          let qty = prime_json[this.state.prime_filler_inventory_id].qty;
          var demo_qty = qty;
          //   count scrap amount of prime section
          let scrap_factor = getScrap(prime_json, this.state.prime_filler_inventory_id);
          let scrap_amount = Number(getScrapAmount(scrap_factor, value)).toFixed(2);

          //   add florish section value
          let florish_section_value = 0;
          let florish_scrap_amount = 0;

          this.state.organisms &&
            this.state.organisms.map((data) => {
              florish_section_value = data.inventory_id === this.state.prime_filler_inventory_id ? Number(data.final_amount_added) : 0;

              florish_scrap_amount = data.inventory_id === this.state.prime_filler_inventory_id ? Number(data.scrap_amount) : 0;

              qty = qty - (Number(florish_section_value) + Number(florish_scrap_amount));
            });

          florish_section_value += Number(florish_scrap_amount);
          var total_amount = Number(value) + Number(scrap_amount) + Number(florish_section_value);

          if (total_amount > qty && total_amount > demo_qty) {
            /*
            Note:-  when user input excided then pop show and
                        then florish amount deduct and after 
                        that find the scrap amount
                        */
            var demoQty = qty;
            scrap_amount = Number(getScrapAmount(scrap_factor, qty)).toFixed(2);

            qty -= scrap_amount;
            this.setState({
              is_prime_filler_amount_added_exceeding_qty: true,
              check: demoQty,
            });
            value = qty.toFixed(2);
          }
          this.setState({
            prime_total_amount_added: getPrimeTotalAmountAdded(this.state.prime_flourish_amount_added, value),
            prime_filler_scrap_amount: scrap_amount,
          });
        } else {
          alert("Error in Prime Filler Inventory id");
        }

        break;
      }

      // Handles the change in Number of Months.
      case "number_of_months": {
        if (!isNaN(value)) {
          formulation_factor = 0;
          let formulation_factors;
          form_errors.number_of_months = value > 0 ? "" : "Number of Months can not be negative";

          if (validation_product_type.indexOf(this.state.product_type) !== -1) {
            formulation_factors = getFormulationFactorForClinicalFormula(value);
          } else {
            formulation_factors = getFormulationFactor(value);
          }

          let final_amount_added = getFinalAmountAdded(formulation_factors, this.state.amount_added);
          let total_final_amount_added = getTotalFinalAmountAdded(formulation_factors, this.state.total_amount_added);
          let total_cfu = getTotalCFU(this.state.concentration, final_amount_added);

          this.setState({
            total_final_amount_added,
            formulation_factor: formulation_factors,
            final_amount_added,
            total_cfu,
            form_errors,
          });
        }

        break;
      }

      case "prime_filler_inventory_id": {
        let form_errors = { ...this.state.form_errors };

        prime_section_arr.id = value;

        var list  = this.state.inventory_options_prime.filter((item)=> item.key == value)
        var allergen  = list[0].allergen;
          
        if(allergen && allergen.length>0 && this.state.allergen_info.length>0 && !ingredientAdded){
          const thirdArray = this.state.allergen_info.filter((elem) => { return allergen.some((ele) => {return elem.includes(ele);}); });
        
          if(thirdArray.length>0){
          var tempValue = thirdArray.join(",");
          function replaceAt(str, index, replacement) {
            if (index < 0) return str;
            return str.substring(0, index) + replacement + str.substring(index + 1);
          }
          warning = "Prime Allergic Warning";
          popUp_description = "Customer is allergic to <b>"+ replaceAt(tempValue, tempValue.lastIndexOf(','),' & ')+"</b> . Are you sure you want to add <b>"+ list[0].text+"</b> anyway?"
          
          this.setState({ is_popUp_disabled: true, popUp_description: "" });
          return;
            }
          }
        ingredientAdded = false;

        form_errors.prime_filler_inventory_id = "";
        let qty = prime_json[value].qty;
        let scrap_factor = getScrap(prime_json, value);

        if (value != null) {
          var demo_qty = qty;
          let scrap_amount = Number(getScrapAmount(scrap_factor, value)).toFixed(2);
          // quantity

          //   add florish section value
          let florish_section_value = 0;
          let florish_scrap_amount = 0;

          this.state.organisms &&
            this.state.organisms.map((data) => {
              florish_section_value = data.inventory_id === value ? Number(data.final_amount_added) : 0;

              florish_scrap_amount = data.inventory_id === value ? Number(data.scrap_amount) : 0;

              qty = qty - (Number(florish_section_value) + Number(florish_scrap_amount));
            });

          florish_section_value += Number(florish_scrap_amount);
          var total_amount = Number(this.state.prime_filler_amount_added) + Number(scrap_amount) + Number(florish_section_value);

          if (total_amount > qty && total_amount > demo_qty) {
            demoQty = qty;
            scrap_amount = Number(getScrapAmount(scrap_factor, qty)).toFixed(2);

            qty -= scrap_amount;

            this.setState({
              is_prime_filler_amount_added_exceeding_qty: true,
              check: demoQty,
            });
            this.setState({
              prime_filler_amount_added: qty.toFixed(2),
              prime_filler_inventory_id: value,
              prime_filler_scrap_amount: scrap_amount,
              prime_total_amount_added: this.state.prime_flourish_amount_added + qty,
            });
          }
          if (this.state.inventory_id !== "" && this.state.inventory_id == this.state.prime_filler_inventory_id) {
            this.setState({ amount_added: 0 });
          }
        } else {
          this.setState({
            form_errors,
            prime_filler_amount_added: this.state.prime_filler_amount_added,
            prime_filler_inventory_id: value,
            prime_filler_scrap_amount: Number(getScrapAmount(scrap_factor, this.state.prime_filler_amount_added)).toFixed(2),
            prime_total_amount_added: this.state.prime_flourish_amount_added + this.state.prime_filler_amount_added,
          });
        }
        this.setState({ form_errors });

        break;
      }
      // Handles the change in Organism.
      case "inventory_id": {
        let concentration = this.state.converted_reqd_inventory_fields_json[value].concentration;
        let scrap_factor = this.state.converted_reqd_inventory_fields_json[value].scrap_factor;

        let scrap_amount = getScrapAmount(scrap_factor, this.state.amount_added);

        let total_cfu = getTotalCFU(concentration, this.state.final_amount_added);

        let final_concentration = getFinalConcentration(concentration, this.state.amount_added, this.state.total_amount_added);

        let total_final_concentration = getTotalFinalConcentration(this.state.total_final_concentration, final_concentration, this.state.final_concentration);

        this.setState({
          concentration,
          scrap_amount,
          total_cfu,
          final_concentration,
          total_final_concentration,
        });
        break;
      }

      case "amount_added": {
        // If the organism is not added yet, returns an aler.

        digitsAfterPointOrganism();
        if (this.state.inventory_id === 0) {
          alert("Please enter the Organism");
          return;
        }

        this.setState({ Check_formula_value: value });

        // check quantity of particular ingredient
        let qty = this.state.converted_reqd_inventory_fields_json[this.state.inventory_id].qty;
        var demo_qty = qty;
        /* Task Improve inventory management - part 2 Start */

        let prime_Section_value = 0;
        let prime_section_scrap_amount = 0;

        if (Object.keys(prime_section_arr).length !== 0 && this.state.inventory_id === prime_section_arr["id"] && this.state.is_prime_added) {
          prime_Section_value = this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_amount_added) : 0;
          prime_section_scrap_amount = this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;

          let x = value;
          if (x + Number(prime_Section_value) + Number(prime_section_scrap_amount) < qty) {
            qty = qty - (Number(prime_Section_value) + Number(prime_section_scrap_amount));
            if (x <= qty) {
              qty = x;
            }
          } else {
            qty = qty - (Number(prime_Section_value) + Number(prime_section_scrap_amount));
          }
        }

        prime_Section_value += prime_section_scrap_amount;

        /* Task:- Improve inventory management - part 2 End */
        var formulation_factor_multiply = value * this.state.formulation_factor;
        let scrap_factor = getScrap(this.state.converted_reqd_inventory_fields_json, this.state.inventory_id);
        let scrap_amount = Number(getScrapAmount(scrap_factor, formulation_factor_multiply)).toFixed(2);

        var x = formulation_factor_multiply + Number(scrap_amount) + Number(prime_Section_value);

        if (x > qty && x > demo_qty) {
          /*
          Note:-  when user input excided then pop show and
                  then prime section amount deduct and after 
                  that find the scrap amount
                    */
          var error_qty = 0;
          if (qty > 0) {
            qty = qty / this.state.formulation_factor;
            error_qty = qty;
            scrap_amount = Number(getScrapAmount(scrap_factor, qty)).toFixed(2);
            qty -= scrap_amount;
            scrap_amount = scrap_amount * this.state.formulation_factor;
          }
          value = Number(qty).toFixed(2);
          this.setState({
            is_amount_added_exceeding_qty: true,
            check: Number(error_qty).toFixed(2),
            check_insert: value,
          });
        }

        temprary_value = Number(value);

        // final_concentration
        let final_concentration = getFinalConcentration(this.state.concentration, value, total_amount_added);

        //all dependencies get changed
        let final_amount_added = getFinalAmountAdded(this.state.formulation_check, value);

        this.setState({
          amount_added: value,
          scrap_amount,
          final_concentration,
          final_amount_added,
        });
        break;
      }
      default:
    }

    this.setState({ [name]: value });
  };


  fetchDataPrime = () => {
    // Create inventory options
    let inventory_options_prime = [];

    customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.REQUEST, baseURL + "/inventory/inventoryOptions");

    // API call to get the inventory options
    axios
      .get(baseURL + "/inventory/inventoryOptions?type=pre,pro,prm", getHeaders())
      .then((response) => {
        customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.RESPONSE, response);

        const reqd_inventory_fields_json = response.data;

        const converted_reqd_inventory_fields_json = convertReqdInventoryOptionsJSON(reqd_inventory_fields_json);

        prime_json = converted_reqd_inventory_fields_json;

        // Add organisms to inventory_options.
        reqd_inventory_fields_json.forEach((organism) => {
          inventory_options_prime.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            allergen:organism.allergens
          });
        });

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options_prime,
            is_page_loading: false,
          });
        }
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  };


  getUserPermission = () => {
    let Role = getUserRole();
    let login = localStorage.getItem("login_success");

    if (login == 1) {
      axios
        .get(baseURL + "/users/PermissionDetails", getHeaders())
        .then((response) => {
          let permissionData = null;

          permissionData = simplifyModulePermissionData(response.data);
          permissionData = permissionData.filter((data) => {
            if (data.role_name === Role) {
              return data;
            }
          });

          let createFormulation = 0;

          let disabledPermission;
          let createFormulationpermission = permissionData.forEach((result) => {
            if (result.module_name == "Create Formulation" && result.edit_permission === 1) {
              createFormulation = 1;
              disabledPermission = false;
            }
          });

          if (createFormulation == 0) {
            createFormulationpermission = 0;
            disabledPermission = true;
          } else {
            createFormulationpermission = 1;
          }

          this.setState({
            createFormulationpermission,
            createFormulation,
            disabledPermission,
          });
        })
        .catch((error) => {
          console.log("Fetching Display Grid | Error: ", error);
        });
    }
  };
  // call on sample id change
  handleSelectedSample = (sample) => {
    
    this.fetchDataPrime();
    tempArray_tag = [];
    inventoryArr = [];
    this.userInputArr = [];

    total_amount_added = 0;
    let inventory_options = [];
    handle_total_cfu = 0;
    prime_section_arr = {};

    formulation_factor = 0;
    prime_section_arr = {};

    customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.REQUEST, baseURL + "/inventory/inventoryOptions");

    axios
      .post(portalURL + "/getMicrobes", {
        token: Config.token,
        sg_number: sample.sg_number,
        time_point: sample.sg_number_count,
      })
      .then((response) => {
        this.setState({
          influenced_microbes: response.data.data,
          influenced_microbes_heading: response.data.heading,          
        });

        if(response.data.clinicalInfo && Object.keys(response.data.clinicalInfo).length>0){
          this.setState({clinical_info : response.data.clinicalInfo})
        }

      });
    var url = "/inventory/inventoryOptions?type=pre,pro,for";

    axios
      .get(baseURL + url, getHeaders())
      .then((response) => {
        customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.RESPONSE, response);
        const reqd_inventory_fields_json = response.data;
        const converted_reqd_inventory_fields_json = convertReqdInventoryOptionsJSON(reqd_inventory_fields_json);
        reqd_inventory_fields_json.forEach((organism) => {
          inventory_options.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            allergen:organism.allergens
          });
        });

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options,
            is_page_loading: false,
          });
        }
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });

    let previousSample = this.state.selectedSample;
    this.setState({ prev_sg_number: sample });
    if (product_type_array.includes(sample.product_type)) {
      if (sample.sg_number !== previousSample.sg_number) {
        this.setState({
          pastFormulation: false,
          Selected_Sample: sample,
          selectedSample: sample,
          sample_id: sample.sg_number,
          product_type: sample.product_type,
          number_of_months: 6,
          previos_number_of_months: 6,
          formulation_factor: 2,
          total_amount_added: 0,
          total_final_concentration: 0,
          total_final_amount_added: 0,
          organisms: [],
          prime_filler_inventory_id: "",
          prime_filler_scrap_amount: 0,
          dataUp: [],
          dataUp_gain: 0,
          dataUp_amountAdded: 0,
          beforeInsertCfu: 0,
          dataUp_final_amount_added: 0,
          amount_added: "",
          gain: 0,
          pendding_amount_added: 0,
          pendding_total_cfu: 0,
          pendding_total_amount_added: 0,
          prime_filler_amount_added: 4.8,
          prime_total_amount_added: this.state.prime_flourish_amount_added + 4.8,
          is_prime_added: true,
          is_prime_added_disabled: false,
          is_number_of_month_disabled: false,
        });
      }
    } else {
      if (sample.sg_number !== previousSample.sg_number) {
        this.setState({
          pastFormulation: false,
          Selected_Sample: sample,
          total_amount_added: 0,
          amount_added: "",
          total_final_concentration: 0,
          total_final_amount_added: 0,
          organisms: [],
          dataUp: [],
          prime_filler_scrap_amount: 0,
          prime_filler_inventory_id: "",
          beforeInsertCfu: 0,
          dataUp_gain: 0,
          dataUp_amountAdded: 0,
          dataUp_final_amount_added: 0,
          gain: 0,
          pendding_amount_added: 0,
          pendding_total_cfu: 0,
          pendding_total_amount_added: 0,
          prime_filler_amount_added: 4.8,
          valueEdit: false,
          is_number_of_month_disabled: false,
          prime_total_amount_added: this.state.prime_flourish_amount_added + 4.8,
          is_prime_added: true,
          is_prime_added_disabled: false,
          is_number_of_month_disabled: false,
        });
      }

      this.setState({
        pastFormulation: false,
        selectedSample: sample,
        sample_id: sample.sg_number,
        product_type: sample.product_type,
        number_of_months: 3,
        previos_number_of_months: 3,
        formulation_factor: 1,
        is_prime_added: true,
        is_prime_added_disabled: false,
        is_number_of_month_disabled: false,
      });
    }

    let powder = "powder";
    if (sample.flore_probiotics_name === "powder") {
      this.setState({
        flourish_capsule_size: powder,
        prime_capsule_size: powder,
        number_of_months: 3,
        previos_number_of_months: 3,
        formulation_factor: 1,
        organisms: [],
      });
    } else {
      this.setState({
        flourish_capsule_size: 0,
        prime_capsule_size: 0,
      });
    }
    var conditionCheck = false;
    var months_number = 0;

    if(product_type_six_twelve_validation.indexOf(sample.product_type)> -1){      
      months_number = probiotic_product_type.indexOf(sample.product_type) !== -1 ? six_twelve_month_wise_validation.probiotics : six_twelve_month_wise_validation.prebiotics;
      conditionCheck = true;
    }else if (validation_product_type.indexOf(sample.product_type) !== -1
     || product_type_probiotics_validation.indexOf(sample.product_type) !== -1) {

      months_number = product_type_probiotics_validation.indexOf(sample.product_type) !== -1 ?
       month_wise_validation.probiotics : month_wise_validation.prebiotics;
      
      // 3 month pre / probiotics validation
      if(sample.product_type.includes('(3 months)')){
        months_number = product_type_3_month.indexOf(sample.product_type)>-1?month_wise_validation.probiotics_3:
        month_wise_validation.prebiotics_3
      }

      sample.product_type.includes('Floré Clinical Upgrade (2 months) / prebiotics') && (months_number = month_wise_validation.prebiotics_3)
      sample.product_type.includes('Floré Clinical Upgrade (3 months) / probiotics') && (months_number = month_wise_validation.probiotics_3)
      sample.product_type.includes('Floré Clinical Upgrade (4 months) / prebiotics') && (months_number = month_wise_validation.prebiotics)
      sample.product_type.includes('Floré Clinical Upgrade (5 months) / probiotics') && (months_number = month_wise_validation.probiotics)
      conditionCheck = true;
    }
    
    var probiotics_condition = sample.product_type.includes("probiotics");
    var checkFloreBasic = sample.product_type.includes('Floré Basic');
    
    if(checkFloreBasic){
      probiotics_condition = checkFloreBasic;
      months_number = 3;
    }
    
    if(conditionCheck || checkFloreBasic){
      this.setState({
        is_prime_added: probiotics_condition,
        number_of_months: months_number,
        is_prime_added_disabled: !probiotics_condition,
        organisms: [],
        formulation_factor: probiotics_condition ? (months_number / 3).toFixed(3) : (months_number * 30).toFixed(3),
        flourish_capsule_size: probiotics_condition ? this.state.flourish_capsule_size : "powder",
        is_number_of_month_disabled: !probiotics_condition,
      });
    }
    

    document.getElementById("reformulation_sample_id").value = sample.sg_number;
    const queryParameters = new URLSearchParams(window.location.search)
    let sample_id = queryParameters.get("sample_id");    
    if(sample_id !== null && sample_id !== ""){
     let numberOfMonths = 3
      this.setState({number_of_months: numberOfMonths})
      this.setState({reformulate: false})
    }else{
      var product_type_name = Product_Type[sample.product_type];
      axios.get(baseURL + "/check_reformulation/" + sample.sg_number + "/" + product_type_name, getHeaders()).then((response) => {
        this.setState({ reformulate: response.data.success });
      });
    } 
    
    if(clinical_validation_product_type.indexOf(sample.product_type) !== -1){
      this.setState({ is_clinical: true });
    }else{
      this.setState({ is_clinical: false });
    }
    
  };
  // delete organism on click
  onDeleteOrganismClick = (organism_barcode) => {
    let { organisms } = this.state;

    let type = organism_barcode.split("_")[0];

    if (organisms.length === 1) {
      this.userInputArr = [];
      this.getallIngredient("pre,pro,for");
    } else if (type === "FOR" && organisms.length) {
      this.insert("for", this.userInputArr);
    } else if ((type === "PRE" || type === "PRO") && organisms.length) {
      this.insert("pre,pro", this.userInputArr);
    }
    /**
     * Handles weird case where enter press
     * inside "amount added" input on create formulations page
     * triggers this function call
     */
    if (document.activeElement.tagName === "INPUT") {
      return;
    }

    this.userInputArr.splice(
      this.userInputArr.findIndex((a) => a === organism_barcode),
      1
    );

    organisms.splice(
      organisms.findIndex((a) => a.organism_barcode === organism_barcode),
      1
    );

    this.setState({ organisms });

    var total_amount_added = 0;
    var total_cfu = 0;
    organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    total_amount_added += this.state.pendding_amount_added;
    total_cfu += this.state.pendding_total_cfu;

    this.setState({
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added) ? 0 : total_cfu / total_amount_added,
      total_final_amount_added: this.state.formulation_factor * total_amount_added,
    });

    // }

    // Add the deleted organism barcode, to the inventory_options
    let inventory_options = [...this.state.inventory_options];

    this.state.reqd_inventory_fields_json.forEach((organism) => {
      if (organism.organism_barcode === organism_barcode) {
        inventory_options.unshift({
          key: organism.id,
          value: organism.id,
          text: organism.organism_barcode,
          icon: "dna",
          allergen:organism.allergens
        });
      }
    });
  };

  // add organism on  click
  onAddOrganismClick = () => {
    temprary_value = 0;

    var list  = this.state.inventory_options.filter((item)=> item.text == this.state.organism_barcode)
    var allergen = list[0].allergen;

    if(allergen.length>0 && this.state.allergen_info.length>0 && !ingredientAdded){
      const thirdArray = this.state.allergen_info.filter((elem) => { return allergen.some((ele) => {return elem.includes(ele);}); });
    
      if(thirdArray.length>0){
      var tempValue = thirdArray.join(",");
    function replaceAt(str, index, replacement) {
      console.log(index);
      if (index < 0) {
        return str;
      }
      return str.substring(0, index) + replacement + str.substring(index + 1);
    }
    warning = "Allergic Warning";
    popUp_description = "Customer is allergic to <b>"+ replaceAt(tempValue, tempValue.lastIndexOf(','),' & ')+"</b> . Are you sure you want to add <b>"+ this.state.organism_barcode+"</b> anyway?"
    
    this.setState({ is_popUp_disabled: true, popUp_description: "" });
    return;
    
  }
  }
  ingredientAdded = false;

    let check_1 = this.state.check_insert / this.state.formulation_factor;

    if (this.state.inventory_id !== 0) {
      inventoryArr[count] = this.state.inventory_id;
      count++;
    }

    // if there is no organism and amount_added inserted, return.
    if (this.state.amount_added == 0 || this.state.inventory_id === 0 || this.state.amount_added === "") {
      alert("Please select both the ingredient Barcode and the Amount added!");
      return;
    }

    // clones already added organisms
    let organisms = [...this.state.organisms];

    // Adds the new 'organism'.
    let prime_section_value = 0;

    if (this.state.inventory_id === this.state.prime_filler_inventory_id) {
      prime_section_value = this.state.prime_filler_amount_added;
    }

    organisms.push({
      inventory_id: this.state.inventory_id,
      organism_barcode: this.state.organism_barcode,
      amount_added: this.state.amount_added,
      delete_scrap_amount: this.state.delete_scrap_amount,
      concentration: this.state.concentration,
      final_concentration: this.state.final_concentration,
      scrap_amount: this.state.scrap_amount,
      final_amount_added: this.state.formulation_factor * this.state.final_amount_added,
      total_cfu: this.state.amount_added * this.state.concentration <= 0 ? 0 : this.state.amount_added * this.state.concentration,
      tags: this.state.tags,
      nonSelectedTag: this.state.multiSelectData,
    });
    this.setState({ organisms });
    var total_amount_added = 0;
    var total_cfu = 0;

    organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    total_amount_added += this.state.pendding_amount_added;
    total_cfu += this.state.pendding_total_cfu;

    this.setState({
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      // florish_section_flag:false,
      gain: isNaN(total_cfu / total_amount_added) ? 0 : total_cfu / total_amount_added,
      total_final_amount_added: this.state.formulation_factor * total_amount_added,
    });

    // Removes the new organism from the inventory_options list.
    for (var i = 0; i < this.userInputArr.length; i++) {
      for (var j = 0; j < this.state.inventory_options.length; j++) {
        if (this.state.inventory_options[j]["text"] === this.userInputArr[i]) {
          this.state.inventory_options[j]["active"] = false;
        }
      }
    }

    // clear when tag added in dropdown
    this.multiselectRef.current.clearValue();
    handle_total_cfu = 0;

    let { organism_barcode } = this.state;
    this.userInputArr = [...this.userInputArr, organism_barcode];
    type = this.state.organism_barcode.split("_")[0];

    if (type === "FOR") {
      this.reuseFetchApi("for", this.userInputArr);
    } else if (type === "PRE" || type === "PRO") {
      this.reuseFetchApi("pre,pro", this.userInputArr);
    }
    // Resets the organism state values.
    this.setState(this.resetOrganismState());
  };

  resetOrganismState = () => {
    return {
      organism_barcode: "",
      inventory_id: 0,
      amount_added: "",
      concentration: 0,
      final_concentration: 0,
      scrap_amount: 0,
      final_amount_added: 0,
      total_cfu: 0,
      tags: [],
    };
  };

  // submit data .
  onSubmitClick = (event) => {
    event.preventDefault();

    // Validation check.
    if (isFormValid(this.state)) {
      this.setState({
        // product_type: selectedProduct,
        is_saving_successful: false,
        is_saving_failed: false,
      });

      let res = _.pick(this.state, ["sample_id", "formula_id", "date_created", "lot", "number_of_months", "formulation_factor", "admin_notes", "reformulate", "prime_filler_inventory_id", "prime_flourish_amount_added", "prime_filler_amount_added", "prime_filler_scrap_amount", "prime_total_amount_added", "flourish_capsule_size", "product_type", "organisms", "delete_prime_scrap_amount", "total_amount_added", "total_final_concentration", "total_final_amount_added","formula_type"]);
      
      res.product_type = Product_Type[res.product_type];
      if (Clinical_Prebiotic.includes(res.product_type)) {
          res.formula_type = "pre";
          this.setState({formula_type:"pre"});          
      }

      

      let return_flag = false;
      res.organisms.forEach((item) => {
        if (item.amount_added <= 0) {
          return_flag = true;
          alert(`Organisms ${item.organism_barcode} Amount Added Value Can't be blank`);
          return;
        }
        var x = [];
        item.tags.map((a) => x.push({ name: a.label, id: a.id }));
        item.tags = x;
        item.final_amount_added = item.amount_added * res.formulation_factor;
        item.total_cfu = item.concentration * item.final_amount_added;
      });
      if (return_flag !== false) {
        this.setState({ is_page_loading: false });
        return;
      }
      res.user_id = getSessionStoredJsonItem("user").user_id;
      res.total_final_concentration = this.state.gain;
      res.date_created = getDateString(this.state.date_created);
      res.prime_capsule_size = this.state.is_prime_added ? this.state.prime_capsule_size : "";
      // res.product_type = 'Floré Clinical Upgrade (4 months) / prebiotics';


      this.setState({ is_waiting_for_response: true });
      // API call to save the formulation data
      customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.REQUEST, baseURL + "/formulations", res);
      
      axios
        .post(baseURL + "/formulations", res, getHeaders())
        .then((response) => {
          customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.RESPONSE, response);

          let init_state = this.getInitState();
          init_state["inventory_options"] = this.state.inventory_options;
          init_state["converted_reqd_inventory_fields_json"] = this.state.converted_reqd_inventory_fields_json;
          init_state["reqd_inventory_fields_json"] = this.state.reqd_inventory_fields_json;

          init_state["is_saving_successful"] = response.data.success;
          init_state["is_saving_failed"] = !response.data.success;
          init_state["response_message"] = response.data.message;
          init_state["sample_id_message"] = response.data.sample_id;
          init_state["is_page_loading"] = false;
          init_state["is_waiting_for_response"] = false;
          init_state["is_message_visible"] = true;
          init_state["flourish_capsule_size"] = this.state.flourish_capsule_size;
          init_state["prime_capsule_size"] = this.state.prime_capsule_size;
          init_state["product_type"] = this.state.product_type;
          this.setState(init_state);
          this.setState({ formula_id: response.data.formula_id });
          if (response.data.formula_id != null) {
            document.getElementById("formulaColumn").style.display = "block";
          }
          focusFirstElement();
          window.scrollTo(0, 0);
          if (response.data.success === true) {
            const interval = setInterval(() => {
              window.location.reload(true);
            }, 2000);
          }
        })
        .catch((error) => {
          alert("Create Formulation | Error: " + error);
          this.setState({ is_page_loading: false });
        });

      this.setState({ is_page_loading: false });
    } else {
      alert("Please remove the errors shown in red font prior to submiting the form.");
    }
  };

  // Handles cancel click.
  onCancelClick = () => {
    let init_state = this.getInitState();
    init_state["is_page_loading"] = false;
    init_state["inventory_options"] = this.state.inventory_options;
    init_state["converted_reqd_inventory_fields_json"] = this.state.converted_reqd_inventory_fields_json;
    init_state["reqd_inventory_fields_json"] = this.state.reqd_inventory_fields_json;

    this.setState(init_state);
  };

  floatFunction = (value) => {
    var numberOfMonths;
    var formula_type = "pro";
    numberOfMonths = ["Monthly Floré Adult", "Monthly Floré Tots", "Monthly Floré Kids", "Monthly Floré Baby", "Monthly Floré Earned"].includes(value) ? 6 : 3;    
    if(product_type_six_twelve_validation.indexOf(value)> -1){
      numberOfMonths = probiotic_product_type.indexOf(value) !== -1 ? six_twelve_month_wise_validation.probiotics : six_twelve_month_wise_validation.prebiotics;
    }else if (validation_product_type.indexOf(value) > -1 || product_type_probiotics_validation.indexOf(value) > -1) {
      numberOfMonths = product_type_probiotics_validation.indexOf(value) !== -1 ? month_wise_validation.probiotics : month_wise_validation.prebiotics;
    }
    const queryParameters = new URLSearchParams(window.location.search)
    let sample_id = queryParameters.get("sample_id");    
    if(sample_id !== null && sample_id !== ""){
      numberOfMonths = 3;
      formula_type = "pre";
    }
    this.setState({ product_type: value, number_of_months: numberOfMonths, formulation_factor: (numberOfMonths / 3).toFixed(3),formula_type:formula_type });
    console.log(this.setState);
    
  };

  sampleFunction = (value) => {
    this.setState({
      sample_id: value,
      new_save: false,
    });
  };

  handlerProductType = (value) => {
    this.setState({ product_type: value });
  };

  onClickOutside = () => {
    this.setState({
      is_amount_added_exceeding_qty: false,
      is_prime_filler_amount_added_exceeding_qty: false,
      is_organism_already_added: false,
    });
  };

  // Hides the SUCCESS/FAILURE message
  hideMessage = () => {
    this.setState({ is_message_visible: false });
  };

  handleRationaleBuildSubmitted = (ailments) => {
    this.setState({
      selectedAilments: ailments,
    });
  };

  handleSetAilmentAnswer = (answer) => {
    this.setState({
      ...this.state,
      ailmentAnswer: answer,
    });
  };

  getMultiSelectTask = (value) => {
    if (value && value.length) {
      let emptyArray = [];
      value &&
        value.map((elem, index) => {
          let obj = {
            label: elem.tag.tag_name,
            value: elem.tag.tag_name,
            id: elem.tag.tag_id,
          };
          emptyArray.push(obj);
        });
      this.setState({
        multiSelectData: emptyArray,
        organism_barcode_multiselectedData: emptyArray,
      });
    } else {
      this.setState({
        multiSelectData: [],
        organism_barcode_multiselectedData: [],
      });
    }
  };

  reuseFetchApi = (type, userInputArr = []) => {
    let inventory_options = [];

    customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.REQUEST, baseURL + "/inventory/inventoryOptions");

    // API call to get the inventory options
    axios
      .get(baseURL + `/inventory/inventoryOptions?type=${type}`, getHeaders())
      .then((response) => {
        customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.RESPONSE, response);

        let reqd_inventory_fields_json = response.data;

        let converted_reqd_inventory_fields_json = convertReqdInventoryOptionsJSON(reqd_inventory_fields_json);

        // Add organisms to inventory_options.
        reqd_inventory_fields_json.forEach((organism) => {
          inventory_options.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            allergen:organism.allergens
          });
        });

        let counter = 0;
        counter++;

        if (userInputArr && userInputArr.length) {
          userInputArr.filter(function (elem) {
            inventory_options = inventory_options.filter(function (sa) {
              return sa.text !== elem;
            });
          });
        }

        // inventory_options_tag = inventory_options;

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options,
            is_page_loading: false,
          });
        }
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  };

  insert = (type, userInputArr = []) => {
    let inventory_options = [];

    customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.REQUEST, baseURL + "/inventory/inventoryOptions");

    // API call to get the inventory options
    axios
      .get(baseURL + `/inventory/inventoryOptions?type=${type}`, getHeaders())
      .then((response) => {
        customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.RESPONSE, response);

        let reqd_inventory_fields_json = response.data;

        let converted_reqd_inventory_fields_json = convertReqdInventoryOptionsJSON(reqd_inventory_fields_json);

        // Add organisms to inventory_options.
        reqd_inventory_fields_json.forEach((organism) => {
          inventory_options.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            allergen:organism.allergens
          });
        });

        let newArray = [];
        newArray.push(this.state.userInputArrPush);
        for (let i = userInputArr.length - 1; i >= 0; i--) {
          for (let j = 0; j < newArray.length; j++) {
            if (this.userInputArr[i] === newArray[j]) {
              userInputArr.splice(i, 1);
            }
          }
        }

        if (userInputArr && userInputArr.length) {
          userInputArr.filter(function (elem) {
            inventory_options = inventory_options.filter(function (sa) {
              return sa.text !== elem;
            });
          });
        }

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options,
            is_page_loading: false,
          });
        }
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  };

  getallIngredient = (type) => {
    let inventory_options = [];

    customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.REQUEST, baseURL + "/inventory/inventoryOptions");

    // API call to get the inventory options
    axios
      .get(baseURL + `/inventory/inventoryOptions?type=${type}`, getHeaders())
      .then((response) => {
        customPrint(Config.formulations.section_title.create, PRINT_CATEGORIES.RESPONSE, response);

        let reqd_inventory_fields_json = response.data;

        let converted_reqd_inventory_fields_json = convertReqdInventoryOptionsJSON(reqd_inventory_fields_json);

        // Add organisms to inventory_options.
        reqd_inventory_fields_json.forEach((organism) => {
          inventory_options.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            allergen:organism.allergens
          });
        });

        if (this.userInputArr && this.userInputArr.length) {
          this.userInputArr.filter(function (elem) {
            inventory_options = inventory_options.filter(function (sa) {
              return sa.text !== elem;
            });
          });
        }

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options,
            is_page_loading: false,
          });
        }
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  };
  getTagDataFromParent = (msg, value) => {
    // upper_added_value = true;
    if (msg !== true) {
      toast.info("Ingredient is not available");
      return;
    }

    let flag = true;
    tempArray_tag = [...this.state.organisms];
    tempArray_tag &&
      tempArray_tag.filter((elem) => {
        if (elem.organism_barcode == value.organism_barcode) {
          flag = false;
          return;
        }
      });

    if (flag !== true) {
      toast.info("Ingredient already exist");
      return;
    }
    if (this.state.converted_reqd_inventory_fields_json[value.inventory_id] === undefined) {
      toast.info("The ingredient is not allowed to add as you already selected other type of ingredients in this formula.");
      return;
    }

    let qty = this.state.converted_reqd_inventory_fields_json[value.inventory_id].qty;

    //handle scrap amount
    /* Task Improve inventory management - part 2 Start */

    let prime_Section_value = 0;
    if (Object.keys(prime_section_arr).length !== 0 && value.inventory_id === prime_section_arr["id"] && this.state.is_prime_added) {
      prime_Section_value = prime_section_arr["value"] !== "" ? prime_section_arr["value"] : 0;
      prime_Section_value += this.state.prime_filler_scrap_amount !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;
    }

    /* Task:- Improve inventory management - part 2 End */

    let scrap_factor = getScrap(this.state.converted_reqd_inventory_fields_json, value.inventory_id);

    var x = value.amount_added;
    let scrap_amount = getScrapAmount(scrap_factor, x);
    x += Number(scrap_amount) + Number(prime_Section_value);
    // when user qty excided to  required qty
    if (x > qty) {
      /* Note:-  when user input excided then pop show and then prime section amount deduct and after that find the scrap amount */
      var demoQty = (qty -= Number(prime_Section_value));
      qty = qty;
      scrap_amount = Number(getScrapAmount(scrap_factor, qty)).toFixed(2);
      qty -= Number(scrap_amount);
      // calculate value on formulation_factor
      value.amount_added = qty;
      this.setState({
        is_amount_added_exceeding_qty: true,
        check: demoQty,
        check_insert: value.amount_added,
      });
    }
    let selectedtag = [];
    let data;
    let selected_tags_arr = [];
    if (Object.keys(value.tags).length > 0) {
      value.tags.map((item) => {
        selected_tags_arr.push({
          label: item.tag_name,
          value: item.tag_name,
          id: item.tag_id,
        });
        //   data = {
        //     label: value.current_tag.tag_name,
        //     value: value.current_tag.tag_name,
        //     id: value.current_tag.tag_id,
        //   };
      });
      // selectedtag.push(data);
    }

    obj_tag = {
      inventory_id: value.inventory_id,
      organism_barcode: value.organism_barcode,
      amount_added: value.amount_added,
      concentration: value.concentration,
      final_concentration: value.final_concentration,
      final_amount_added: this.state.formulation_factor * value.amount_added,
      total_cfu: value.amount_added * value.concentration,
      scrap_amount: value.amount_added == 0 ? 0 : scrap_amount,
      tags: selected_tags_arr.length > 0 ? selected_tags_arr : [],
      selected_tags: selected_tags_arr.length > 0 ? selected_tags_arr : [],
      total_final_amount_add_calculation: value.amount_added,
      nonSelectedTag: this.state.multiSelectData,
    };
    tempArray_tag.push(obj_tag);

    // this.calculatevalue(tempArray_tag);
    var total_amount_added = 0;
    var total_cfu = 0;

    let organisms = [...this.state.organisms];
    organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    total_amount_added += this.state.pendding_amount_added + Number(obj_tag.amount_added);
    total_cfu += this.state.pendding_total_cfu + Number(obj_tag.total_cfu);

    this.setState({
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added) ? 0 : total_cfu / total_amount_added,
      total_final_amount_added: this.state.formulation_factor * total_amount_added,
    });

    this.setState({
      organisms: tempArray_tag,
    });
    // notification for success
    toast.success("Ingredient added");

    this.userInputArr = [...this.userInputArr, value.organism_barcode];
    type = value.organism_barcode.split("_")[0];
    if (type === "FOR") {
      this.reuseFetchApi("for", this.userInputArr);
    } else if (type === "PRE" || type === "PRO") {
      this.reuseFetchApi("pre,pro", this.userInputArr);
    }
  };

  deleteUpfromOrganism = (index, value) => {
  
  };
  dataUponChange = (e, id) => {
    if (id == undefined) {
      alert("Please enter the Organism");
      return;
    }

    let qty = this.state.converted_reqd_inventory_fields_json[id].qty;

    if (e.target.value * this.state.formulation_factor > qty) {
      this.setState({
        is_amount_added_exceeding_qty: true,
        check: qty,
        check_insert: qty,
      });
      e.target.value = qty / this.state.formulation_factor;
    }

    var total_amount_added = 0;
    var total_cfu = 0;
    let organisms = [...this.state.organisms];

    organisms.map((item) => {
      if (id === item.inventory_id) {
        item.amount_added = isNaN(e.target.value) ? 0 : e.target.value;
        item.total_cfu = item.amount_added * item.concentration;
        item.final_amount_added = item.amount_added * this.state.formulation_factor;
        item.total_final_amount_add_calculation = item.amount_added;
        total_amount_added += Number(item.amount_added);
        total_cfu += Number(item.total_cfu);
      }
    });

    total_amount_added += this.state.pendding_amount_added;
    total_cfu += this.state.pendding_total_cfu;

    this.setState({
      organisms,
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added) ? 0 : total_cfu / total_amount_added,
      total_final_amount_added: this.state.formulation_factor * total_amount_added,
    });
  };

  onChangeDataUp = (e, inventory_id, barcode) => {
    if (inventory_id == undefined) {
      alert("Please enter the Organism");
      return;
    }

    var qty = this.state.converted_reqd_inventory_fields_json[inventory_id].qty;
    let scrap_factor = getScrap(this.state.converted_reqd_inventory_fields_json, inventory_id);
    let scrap_amount = Number(getScrapAmount(scrap_factor, Number(e.target.value * this.state.formulation_factor)));

    var demoQty = qty;
    if (inventory_id === prime_section_arr["id"]) {
      // quantity
      let prime_Section_value = 0;
      let prime_section_scrap_amount = 0;

      if (Object.keys(prime_section_arr).length !== 0 && inventory_id === prime_section_arr["id"] && this.state.is_prime_added) {
        prime_Section_value = this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_amount_added) : 0;
        prime_section_scrap_amount = this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;
        qty = qty - (Number(prime_Section_value) + Number(prime_section_scrap_amount));
      }
      prime_Section_value += prime_section_scrap_amount;

      var temp_value = Number(e.target.value) * this.state.formulation_factor;
      var x = Number(temp_value) + Number(scrap_amount) + Number(prime_Section_value);

      demoQty = Number(prime_Section_value) == 0 && this.state.is_prime_added ? 0 : demoQty;
      // when user qty excided to  required qty

      if (x > qty && x > demoQty) {
        /*
          Note:-  when user input excided then pop show and
                  then prime section amount deduct and after 
                  that find the scrap amount
        */

        var check_value = (e.target.value = qty / this.state.formulation_factor);
        scrap_amount = Number(getScrapAmount(scrap_factor, e.target.value)).toFixed(2);
        e.target.value -= Number(scrap_amount);
        scrap_amount = Number(scrap_amount) * this.state.formulation_factor;
        // calculate value on formulation_factor
        this.setState({
          is_amount_added_exceeding_qty: true,
          check: check_value,
          check_insert: e.target.value,
        });
      }
      /* Task:- Improve inventory management - part 2 End */
    } else {
      var value = scrap_amount + Number(e.target.value) * this.state.formulation_factor;

      if (value > qty) {
        if (qty > 0) {
          qty = qty / this.state.formulation_factor;
          scrap_amount = Number(getScrapAmount(scrap_factor, qty)).toFixed(2);
          qty -= scrap_amount;
          scrap_amount = Number(scrap_amount) * this.state.formulation_factor;
        }
        value = e.target.value = qty;
        this.setState({
          is_amount_added_exceeding_qty: true,
          check: value.toFixed(2),
          check_insert: Number(e.target.value).toFixed(2),
        });
      }
      scrap_amount = Number(scrap_amount).toFixed(2);
    }

    //handle scrap amount
    let organisms = [...this.state.organisms];
    organisms.map((item) => {
      if (barcode === item.organism_barcode) {
        item.amount_added = Number(parseFloat(e.target.value == "" ? 0 : e.target.value));
        item.total_cfu = item.amount_added * parseFloat(item.concentration == "" ? 0 : item.concentration);
        item.final_amount_added = item.amount_added * this.state.formulation_factor;
        item.scrap_amount = Number(scrap_amount);
      }
    });
    // calculate total final amount added value
    this.calculatevalue([...this.state.organisms]);
  };

  removeDataUp = () => {
    this.setState({
      dataUp: [],
      total_amount_added: 0,
      total_final_amount_added: 0,
      gain: 0,
    });
  };

  onRemoveDataup = (selectedList) => {
    let tempArray = this.state.dataUp;
    tempArray.map((item) => {
      if (selectedList == [] || selectedList == "") {
        item.tags = selectedList;
      } else if (selectedList[0].organism_barcode === item.organism_barcode) {
        item.tags = selectedList;
      }
    });
    this.setState({ dataUp: tempArray });
  };

  onSelectDataUp = (selectedList, selectedItem) => {
    let valueSplit = selectedList[0].organism_barcode.split("_");
    var valueSplit_barcode = valueSplit[0] + "_" + valueSplit[1];
    let tempArray = this.state.dataUp;
    tempArray.map((item) => {
      let itemSplit = item.organism_barcode.split("_");
      var itemSplit_barcode = itemSplit[0] + "_" + itemSplit[1];

      if (valueSplit_barcode === itemSplit_barcode) {
        item.tags = selectedList;
      }
    });
    this.setState({ dataUp: tempArray });
  };

  onRemoveAfterInsert = (selectedList, id) => {
    let tempArray = this.state.organisms;

    tempArray.map((item) => {
      if (selectedList) {
        item.tags = selectedList;
      }
    });
    this.setState({ organisms: tempArray });
  };

  onSelectAfterInsert = (selectedList, bar_code) => {
    var organisms = this.state.organisms;
    organisms.map((item) => {
      if (item.organism_barcode === bar_code) {
        item.tags = selectedList;
      }
    });

    this.setState({ organisms });
  };

  calculatevalue = (organisms) => {
    var total_amount_added = 0;
    var total_cfu = 0;

    organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });
    var factor = formulation_factor !== 0 ? formulation_factor : this.state.formulation_factor;

    this.setState({
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added) ? 0 : total_cfu / total_amount_added,
      total_final_amount_added: factor * total_amount_added,
      formulation_factor: factor,
    });
  };

  Modal_return_value = (value, message) => {
    if (warning == "Prime Allergic Warning") {
      if(!value){
       this.setState({prime_filler_inventory_id:""});
      } 
      else {
       ingredientAdded = true;
       this.onInputChange("",{name: "prime_filler_inventory_id",value : prime_section_arr.id});      
     }
     return;
     }
 
    if (warning == "Allergic Warning") {
      if (!value) {
        this.setState({
          inventory_id: 0,
          organism_barcode: "",
          amount_added: "",
          delete_scrap_amount: "",
          concentration: "",
          concentration: "",
          final_concentration: "",
          scrap_amount: "",
          tags: [],
          total_cfu: "",
        });
        this.multiselectRef.current.clearValue();
        var len = document.querySelectorAll("div[aria-live]");
        len[len.length - 1].innerHTML = "";
        return;
      }
      else {
        ingredientAdded = true;
        this.onAddOrganismClick();
      }
      return;
    }

    if (value) {
      setTimeout(() => {
        if (total_amount_added !== Number(this.state.total_final_amount_added) && warning === "") {
          warning = "quantitiy_warning";
          popUp_description = "Warning! The Total Amount Added  for the formula you're about to create is different from what is expected! Do you still want to proceed?";

          this.setState({ is_popUp_disabled: true, popUp_description: "" });
          return;
        } else {
          this.onSubmitClick(submit_event);
        }
        return;
      }, 500);
    } else {
      warning = "";
      return;
    }
  };
  onConfirm_button = (event) => {
    submit_event = event;
    if (!this.state.product_type) {
      alert("You need to select a product type to create a formulation!");
      return;
    }
    if (temprary_value !== 0) {
      alert("Please insert ingredient");
      return;
    }
    if ((this.state.prime_filler_inventory_id == null || this.state.prime_filler_inventory_id == "") && this.state.is_prime_added == true) {
      alert("Prime is added, please select a filler!");
      return;
    }
    if (this.state.is_prime_added == true && (this.state.prime_filler_amount_added == 0 || this.state.prime_filler_amount_added == "")) {
      alert("Prime Filler Amount Added Can't be blank!");
      return;
    }
    if (this.state.is_prime_added == true && this.state.prime_filler_amount_added !== 0 && this.state.prime_filler_inventory_id == null) {
      alert("Prime is added, please select a filler!");
      return;
    }

    if (this.state.total_amount_added == 0 && this.state.total_final_amount_added == 0) {
      alert("Please Insert Amount");
      return;
    }

    if (this.state.organisms.length == 0) {
      alert("Please select at least one organism barcode from flourish section before creating formula ");
      return;
    }
    if (validation_product_type.indexOf(this.state.product_type) == -1 && product_type_probiotics_validation.indexOf(this.state.product_type) == -1) {
      if ([3, 6].indexOf(Number(this.state.number_of_months)) === -1) {
        popUp_description = "Warning! The number of months for the formula you're about to create is not 3 or 6 months? Do you still want to proceed?";

        this.setState({
          is_popUp_disabled: true,
          popUp_description: "this is working ",
        });
        return;
      } else {
        if (total_amount_added !== Number(this.state.total_final_amount_added)) {
          warning = "quantitiy_warning";
          popUp_description = "Warning! The Total Amount Added  for the formula you're about to create is different from what is expected! Do you still want to proceed?";

          this.setState({
            is_popUp_disabled: true,
            popUp_description: "this is workings",
          });
          return;
        } else {
          this.onSubmitClick(event);
        }
      }
    } else {
      this.onSubmitClick(event);
    }
  };

  // check value excided or not
  check_value_exciede = (value, id) => {
    let arr = this.state.converted_reqd_inventory_fields_json;
    let qty = arr[id].qty;
    let scrap_factor = getScrap(arr, id);
    let scrap_amount = getScrapAmount(scrap_factor, value);

    // add id in state when user input amount excided to remaning qty

    this.setState({
      id_partitaly_value: Number(value) + Number(scrap_amount) >= Number(qty) - Number(scrap_amount) ? id : 0,
    });
  };

  // set pop_up value
  pop_up_open = () => { this.setState({ is_popUp_disabled: false }); };

  openPastFormulas = () => {
    if (this.state.pastFormulation !== true && this.state.sample_id !== "") {
      this.setState({ pastFormulation: true, is_page_loading: true });
      setTimeout(() => {
        window.scrollTo({
          top: 600,
          behavior: "smooth",
        });
      }, 500);
    }
  };
  set_page_loading = (value) => {
    this.setState({ is_page_loading: value });
  };
  change_H_D_survey = (value) => {
    this.setState({ h_d_survey_height: value });
  };

  setSampleId = (value, type) => {
    if (type == "sample_id") {
      this.setState({ sample_id: value });
    } else {
      let form_errors = { ...this.state.form_errors };
      form_errors.sample_id = value;
      this.setState({ form_errors });
    }
  };
  getAllergenInfo_healthSurvey=(data)=>{
     this.setState({allergen_info :data});

  }
  render() {
   //console.log(this.state);
    var x = this.state.flourish_capsule_size === "powder" ? 83 : 45;
    total_amount_added = Number(this.state.formulation_factor) * Number(x);
    if (Object.keys(prime_section_arr).length !== 0) {
      let scrap_factor;
      if (prime_section_arr.id !== undefined) {
        scrap_factor = getScrap(this.state.converted_reqd_inventory_fields_json, prime_section_arr.id);
      }

      prime_section_arr.value = Number(this.state.prime_filler_amount_added);
    }
    return (
      <>
        {this.state.is_page_loading && <CustomLoader />}
        <CustomDimmer
          active={this.state.is_prime_filler_amount_added_exceeding_qty}
          title="Not Enough Quantity!"
          pre_content="Organism Inventory only has ("
          content={this.state.prime_filler_amount_added}
          post_content=" g) of this Prime Filler Organism."
          //methods
          onClickOutside={this.onClickOutside}
        />

        <CustomDimmer
          active={this.state.is_amount_added_exceeding_qty}
          title="Not Enough Quantity!"
          pre_content="Organism Inventory only has ("
          content={this.state.check}
          post_content=" g) of this Organism."
          //methods
          onClickOutside={this.onClickOutside}
        />

        {this.state.is_saving_successful && (
          <SuccessMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Formulations"
            message={this.state.response_message}
          />
        )}

        {this.state.is_saving_failed && (
          <ErrorMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Formulations"
            message={this.state.sample_id_message}
          />
        )}
        {/* sample id  */}
        <Samples
          handleSelectedSample={this.handleSelectedSample}
          getTagDataFromParent={this.getTagDataFromParent}
          openPastFormulas={this.openPastFormulas}
          formulation_ai={false}
        />

        {this.state.pastFormulation && (
          <PastFormulations
            sg_number={this.state.sample_id}
            selectedSample={this.state.selectedSample}
            set_page_loading={this.set_page_loading}
            getTagDataFromParent={this.getTagDataFromParent}
          />
        )}

        <div className="formulation-rationale__surveys__wrapper row">
          <div className="col-md-6">
            <HealthSummary
              selectedSample={this.state.selectedSample}
              handleSetAilmentAnswer={this.handleSetAilmentAnswer}
              h_d_survey_height={this.state.h_d_survey_height}
              getAllergenInfo_healthSurvey = {this.getAllergenInfo_healthSurvey}
            />
          </div>
          <div className="col-md-6">            
            <CustomerProfileNotes
              formulation_ai={false}
              selectedSample={this.state.selectedSample}
              change_H_D_survey={this.change_H_D_survey}
              valueEdit={this.state.valueEdit}
            />
            {/* Influenced Microbes */}
            <InfluencedMicrobes
              influenced_microbes_heading={this.state.influenced_microbes_heading}
              influenced_microbes={this.state.influenced_microbes}
            />
          </div>
        </div>
        {this.state.is_popUp_disabled && (
          <ShowModal
            message={warning}
            Description={popUp_description}
            Modal_return_value={this.Modal_return_value}
            pop_up_open={this.pop_up_open}
            modal_style={"900px"}
          />
        )}
       {this.state.is_clinical && (
          <ClinicalInfo
            formulation_ai={false}
            selectedSample={this.state.selectedSample}
            clinicalInfo={this.state.clinical_info}
            change_H_D_survey={this.change_H_D_survey}
            valueEdit={this.state.valueEdit}
          />
       )}

        <Tags
          selectedSample={this.state.selectedSample}
          getMultiSelectTask={this.getMultiSelectTask}
          getTagDataFromParent={this.getTagDataFromParent}
          removeDataUp={this.removeDataUp}
          arr={arr}

        />
        <Segment
          compact
          className="transparent"
        >
          {this.state.is_waiting_for_response && <CustomLoader loading_text="Saving Formulation Data" />}
          <Form>
            <SampleDataFromTable
              id="formulation_info"
              onChange={this.onInputChange}
              setSampleId={this.setSampleId}
              onDateChange={this.onDateChange}
              sampleFunction={this.sampleFunction}
              handlerProductType={this.handlerProductType}
              admin_notes={this.state.admin_notes}
              disabledPermission={this.state.disabledPermission}
              number_of_months={this.state.number_of_months}
              lot={this.state.lot}
              date_created={this.state.date_created}
              formula_id={this.state.formula_id}
              floatOptions={floatOptions}
              floatFunction={this.floatFunction}
              product_type={this.state.product_type}
              uponValue={this.state.uponValue}
              sample_id={this.state.sample_id}
              form_errors={this.state.form_errors}
              new_save={this.state.new_save}
              changeReformulation={this.changeReformulation}
              reformulate={this.state.reformulate}
              changeInputReformulation={this.changeInputReformulation}
              flore_probiotics={this.flore_probiotics}
              rowData={this.state.rowData}
              />

            <Prime
              prime_filler_inventory_id={this.state.prime_filler_inventory_id}
              prime_flourish_amount_added={this.state.prime_flourish_amount_added}
              prime_filler_amount_added={this.state.prime_filler_amount_added}
              prime_filler_scrap_amount={this.state.prime_filler_scrap_amount}
              prime_total_amount_added={this.state.prime_total_amount_added}
              prime_capsule_size={this.state.prime_capsule_size}
              is_prime_added={this.state.is_prime_added}
              form_errors={this.state.form_errors}
              //methods
              onChange={this.onInputChange}
              changePrimeAddition={this.changePrimeAddition}
              //json
              inventory_options={this.state.inventory_options_prime}
              disabledPermission={this.state.disabledPermission}
              selectCapsuleSizeFunction={this.change}
              options={options}
              is_prime_added_disabled={this.state.is_prime_added_disabled}
            />

            <FlourishFormulation
              options={options}
              tags={this.state.tags}
              onSelect={this.onSelect}
              selectCapsuleSizeFunction={this.change}
              number_of_months={this.state.number_of_months}
              formulation_factor={this.state.formulation_factor}
              //organisms related params
              organisms={this.state.organisms}
              organism_barcode={this.state.organism_barcode}
              amount_added={this.state.amount_added}
              concentration={this.state.concentration}
              final_concentration={this.state.final_concentration}
              scrap_amount={this.state.scrap_amount}
              final_amount_added={this.state.final_amount_added}
              total_cfu={this.state.total_cfu}
              multiSelectData={this.state.multiSelectData}
              //methods
              onChange={this.onInputChange}
              onAddOrganismClick={this.onAddOrganismClick}
              onDeleteOrganismClick={this.onDeleteOrganismClick}
              //json
              inventory_options={this.state.inventory_options}
              available_inventory_json={this.state.available_inventory_json}
              disabledPermission={this.state.disabledPermission}
              flourish_capsule_size={this.state.flourish_capsule_size}
              changeStructureFunction={this.changeStructureFunction}
              multiselectRef={this.multiselectRef}
              onChangeDataUp={this.onChangeDataUp}
              onSelectAfterInsert={this.onSelectAfterInsert}
              formualtion_ai={true}
              is_number_of_month_disabled={this.state.is_number_of_month_disabled}
              is_prime_added_disabled={this.state.is_prime_added_disabled}
            />

            <TotalInfo
              total_amount_added={this.state.total_amount_added}
              total_final_concentration={this.state.total_final_concentration}
              total_final_amount_added={this.state.total_final_amount_added}
              disabledPermission={this.state.disabledPermission}
              gain={this.state.gain}
              onSubmitClick={this.onConfirm_button}
              onCancelClick={this.onCancelClick}
            />
          </Form>
        </Segment>
        <Footer />
      </>
    );
  }
}

export default FormulationRationale;
