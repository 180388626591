import React, { Component } from 'react'
import labelImg from '../../../images/9-right-screen.jpg'
import { Form, Popup, Input, TextArea } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

class WeighingOfFlourishIngredients extends Component {
    state = {organisms:[],performed_by_pin:"",confirmed_by_pin:"",performed_by:"",performed_by_name:"",confirmed_by:"",confirmed_by_name:""}
    
    componentDidMount(){
       this.setState({organisms:this.props.selected_row_value.organisms})        
       this.getCurrentStep('weighing_of_flourish_ingredients');
    }
    getCurrentStep=(step_name)=>{       
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name : step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;
            this.onInputChange(0)(null,{name:"primary_operator","value":""});
            this.onInputChange(0)(null,{name:"secondary_signoff","value":""});
            if(result.success === true && result.current_step !== null){
                let current_step = result.current_step;
                let step_json = JSON.parse(current_step.step_json);             
                this.setState({step_current : current_step});
                this.onInputChange(0)(null,{name:"performed_by_pin","value":current_step.performed_by});
                this.onInputChange(0)(null,{name:"confirmed_by_pin","value":current_step.confirmed_by});             
                this.onInputChange(0)(null,{name:"operator_notes","value":step_json.operator_notes});              
                step_json.organisms.map((org,i)=>{
                    this.state.organisms[i].barcode = org.barcode;
                    this.state.organisms[i].primary_operator = org.primary_operator;
                    this.state.organisms[i].secondary_signoff = org.secondary_signoff;                    
                    this.state.organisms[i].measured = org.measured;                    
                    this.state.organisms[i].foreign_inclusion_check = org.foreign_inclusion_check;                    
                    this.state.organisms[i].required_yield = org.required_yield;                    
                    this.state.organisms[i].yield = org.yield;                    
                })               
                 
            }else{
                this.state.organisms = this.props.selected_row_value.organisms;                
                this.getStep('gathering_of_flourish_ingredients');
                
            }
            
            
        })
    } 
    
    getStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name : step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;
            if(result.success === true && result.current_step !== null){
                let current_step = result.current_step;
                let step_json = JSON.parse(current_step.step_json); 
                this.state.organisms.map((org,i)=>{
                    this.state.organisms[i].ingredient = step_json.organisms[i].ingredient;
                    this.state.organisms[i].barcode = step_json.organisms[i].barcode;
                    this.state.organisms[i].foreign_inclusion_check = "";
                    this.state.organisms[i].primary_operator = "";
                    this.state.organisms[i].secondary_signoff = "";                    
                    this.state.organisms[i].required_yield = this.calculateRequiredYeild(org.final_amount_added);                    
                    this.state.organisms[i].measured = "";                    
                    this.state.organisms[i].yield = "";
                    this.onInputChange(0)(null,{name:"primary_operator","value":""});
                    this.onInputChange(0)(null,{name:"secondary_signoff","value":""});                   
                })            
                /* step_json.organisms.map((org,i)=>{
                    this.state.organisms[i].barcode = org.barcode;
                }) */  
            }
        })
    }


    calculateRequiredYeild(traget_amount){
        return ((1-(traget_amount/(traget_amount-0.1)))*100).toFixed(2)
    }

    calculateMeasured(traget_amount,measured){
        return ((measured/traget_amount)*100).toFixed(2);
    }

    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('gathering_of_flourish_ingredients');        
    };

    handleNextClick = () => {     
        // Call the function passed from the parent
        this.props.onNextClick('addition_of_flourish_ingredients')        
    };

    handlePauseClick = ()=>{
        this.props.onNextClick('pause');
    }
    handleQuitClick = ()=>{
        this.props.onNextClick('quit');
    }
    
    onInputChange = (i)=>(e, { name, value }) => {      
        console.log(name); 
        switch (name) {         
            case 'performed_by_pin':
                if( value.length === 4){
                    this.getPerformUser(value,'performer');
                }else{
                    this.setState({performed_by:"",performed_by_name:""});
                }
                break;
            case 'confirmed_by_pin':
                if( value.length === 4){
                    this.getPerformUser(value,'confirmer');
                }else{
                    this.setState({confirmed_by:"",confirmed_by_name:""});
                }
                break;
            case 'barcode':
                this.state.organisms[i].barcode = value;
                break;
            case 'primary_operator':
                this.state.organisms[i].primary_operator = value;
                break;
            case 'secondary_signoff':
                this.state.organisms[i].secondary_signoff = value;
                break;
            case 'foreign_inclusion_check':
                this.state.organisms[i].foreign_inclusion_check = value;
                break;
            case 'measured':
                this.state.organisms[i].measured = value;
                if(value){
                    this.state.organisms[i].yield =  this.calculateMeasured(this.state.organisms[i].final_amount_added,value);
                }else{
                    this.state.organisms[i].yield="";
                }
                
                break;
        
            default:

                break;
        }
            
      this.setState({ [name]: value });
      
    };

    getPerformUser = (pin,user_type)=>{
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }   
          axios.post(baseURL+"/users/getUserByPin",{pin:pin}, getHeaders())
          .then(res=>{               
            if(res.data.success === true && res.data.user !== null){
                let user = res.data.user;
                if(user_type === 'performer'){                   
                    this.setState({performed_by:user.id,performed_by_name:user.full_name});
                }else{
                    this.setState({confirmed_by:user.id,confirmed_by_name:user.full_name});
                }
                 
              //this.setState({user_full_name:user.full_name}); 
            }
        })
    }


    handleSubmit = ()=>{   
        let input_field = false;
        this.state.organisms.map((value)=>{
            if(value.measured == "" || value.primary_operator == "" || value.secondary_signoff == "" || value.foreign_inclusion_check == "" ){
                input_field = true;
            }
        })        
        if(input_field){
            toast.error("Please fill all inputs.");
            return;
        }

        /* if (!this.state.operator_notes) {
            toast.error("Please fill all inputs.");
            return;
        }   */   
        
        if (!this.state.performed_by_name) {
            toast.error("Please fill vaild pin.");
            return;
        }
        if (!this.state.confirmed_by_name) {
            toast.error("Please fill vaild pin.");
            return;
        }
        let step_json = {operator_notes:this.state.operator_notes}
        let ingredient = [];
        this.state.organisms.map((org)=>{
            ingredient.push({ingredient_barcode:org.inventory.organism_barcode,final_amount_added:org.final_amount_added,scrap_amount:org.scrap_amount,required_yield:org.required_yield,measured:org.measured,yield:org.yield,foreign_inclusion_check:org.foreign_inclusion_check, barcode:org.barcode,primary_operator:org.primary_operator,secondary_signoff:org.secondary_signoff});
        })
        step_json.organisms = ingredient; 
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:9,
            step_json:JSON.stringify(step_json),
            step_name:'weighing_of_flourish_ingredients',
            step_next:'addition_of_flourish_ingredients',
            performed_by:this.state.performed_by_pin,
            confirmed_by:this.state.confirmed_by_pin
        }       
        axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onNextStep();
          }else{
            toast.error("Step has not saved");
          }
        })
    }
    render() {
        console.log(this.props.selected_row_value);
        console.log(this.state);
        return (
            <>
            <Form onSubmit={this.handleSubmit}>    
                <div className='cs-box'>
                    <div className='cs-body'>
                        <h3>Weighing of Flourish Ingredients</h3>
                        <div className='mt-2 row'>
                            <div className='col-md-6 border-r'>
                                <ul className='cs-list'>
                                    <li>Weigh Flourish probiotics:</li>
                                    <li>Ensure hood sash is below "Sash Height" level.</li>
                                </ul>
                                <br />
                                <br />
                                <br />
                                <ul className='cs-list'>
                                    <li>Plug in scale, place empty weight
                                    boat on the scale, and zero the
                                    scale with empty weigh boat.</li>
                                    <li>In a clean weigh boat, pour the
                                    amount of probiotic requested in the
                                    formulation to within +/-0.1g</li>
                                </ul>
                                <br />
                                <br />
                                <br />
                                <ul className='cs-list'>
                                    <li>Pull all ingredients listed on
                                        formulation website using their
                                        target amount displayed and
                                        manually input weight displayed on
                                        scale into formulation system table
                                        to confirm (Yield calculations are
                                        generated automatically and prompt
                                        user if outside acceptable ranges)</li>
                                    <li>operator will visually inspect weigh
                                    material within weigh boat for
                                    possible
                                    inclusions in raw material.</li>
                                    <li>Perform primary and secondary
                                    signoffs to confirm quantities are
                                    accurate and within acceptable
                                    range</li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <img className='w-100' src={labelImg} alt='label' />
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div className='custom-table custom-header-table weighing-ingredients-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th>Ingredient Barcode</th>
                                            <th>Target Amount(g)</th>
                                            <th>Scrap Amount(g)</th>
                                            <th>Required Yield </th>
                                            <th>Scan of Barcode</th>
                                            <th>Measured</th>
                                            <th>Yield (%)</th>
                                            <th>Foreign <br/>Inclusion Check</th>
                                            <th>Primary <br/>Operator</th>
                                            <th>Secondary <br/>Signoff</th>
                                        </tr>
                                        {console.log(this.state.organisms)}
                                        {
                                            
                                            this.state.organisms && this.state.organisms.map((org,i)=>{                                               
                                            return( 
                                                <tr>
                                                    <td>{org.inventory.organism_barcode}</td>
                                                    <td>{org.final_amount_added}</td>
                                                    <td>{(org.scrap_amount).toFixed(2)}</td>
                                                    <td className='text-nowrap text-green'>{org.required_yield}</td>
                                                    <td>{org.barcode}</td>
                                                    <td>
                                                        <Form.Field required fluid="true">
                                                            <Input
                                                            id={`measured_${i}`}
                                                            name="measured"
                                                            onChange={this.onInputChange(i)}            
                                                            value={org.measured?org.measured:""}  
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                    </td>
                                                    <td className='text-nowrap text-green'>{org.yield}</td>
                                                    <td>
                                                        <Form.Field required fluid="true">                    
                                                            <Input
                                                            id={`foreign_inclusion_check_${i}`}
                                                            name="foreign_inclusion_check"
                                                            onChange={this.onInputChange(i)}      
                                                            value={org.foreign_inclusion_check?org.foreign_inclusion_check:""} 
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>                                                         
                                                    </td>
                                                    <td>
                                                        <Form.Field required fluid="true">                    
                                                            <Input
                                                            id={`primary_operator_${i}`}
                                                            name="primary_operator"
                                                            onChange={this.onInputChange(i)}      
                                                            value={org.primary_operator?org.primary_operator:""} 
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>   
                                                    </td>
                                                    <td>
                                                    <Form.Field required fluid="true">                    
                                                            <Input
                                                            id={`secondary_signoff_${i}`}
                                                            name="secondary_signoff"
                                                            onChange={this.onInputChange(i)}  
                                                            value={org.secondary_signoff?org.secondary_signoff:""}
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field> 
                                                    </td>
                                                </tr>
                                            )})
                                        }
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0'>Operator Notes:</h4></div>
                            <div>
                                <Form.Field required fluid="true">                    
                                    <TextArea
                                    id="operator_notes"
                                    name="operator_notes"
                                    onChange={this.onInputChange(0)}
                                    value={this.state.operator_notes}                                        
                                    placeholder="Operator Notes"
                                    className="form-control"
                                    />
                                </Form.Field>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0 text-center mb-1'>Statement Of Deviation Requirements:</h4></div>
                            <div><h5 className='m-0 text-center fw-500'>If any process yields fall outside of stated range, conditional step "3C. Process Deviation" is to be conducted.</h5></div>
                        </div>
                        <div className='text-center mt-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            id="performed_by_pin"
                                            name="performed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.performed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-2 mb-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            id="confirmed_by_pin"
                                            name="confirmed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.confirmed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cs-footer text-center'>
                            <hr />
                            <div className='text-center mt-2'>
                            <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                            <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                            <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                            { this.props.step_name === 'gathering_of_flourish_ingredients' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                            { this.props.step_name !== "gathering_of_flourish_ingredients" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}                        
                            </div>
                        </div>
                </div>
                </Form>
            </>
        )
    }
}
export default WeighingOfFlourishIngredients;
