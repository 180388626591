import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Form, Grid, Header, Icon, Message, Modal, Popup, Segment } from "semantic-ui-react";
import config from "../../configuration/config";
import { baseURL, PRINT_CATEGORIES } from "../../configuration/constants";
import CustomLoader from "../custom_elements/CustomLoader";
import Summary from "./Summary";
import FlourishInfo from "../formulation_mod/start_formulation/FlourishInfo";
import PrimeInfo from "../formulation_mod/start_formulation/PrimeInfo";
import SummaryInfo from "../formulation_mod/start_formulation/SummaryInfo";
import { convertInventoryOptionsJSON, customPrint, focusFirstElement, getHeaders, getUserRole, simplifyModulePermissionData } from "../helper";
let disabledPermission = true;

var converted_inventory_options_json = [];
const QuantityAssurance = () => {
  const [qualityAnalytics, setqualityAnalytics] = useState({
    api_respose: "",
    agGridReactColumn: [
      {
        headerName: "Sample ID",
        field: "sample_id",
        filter: "agTextColumnFilter",
        width: 150,
        sortable: true,
      },
      {
        headerName: "Formula ID",
        field: "formula_id",
        filter: "agTextColumnFilter",
        width: 160,
        sortable: true,
      },
      {
        headerName: "Type",
        field: "type",
        editable: false,
        filter: "agTextColumnFilter",
        width: 160,
        sortable: true,
      },
      {
        headerName: "Product Type",
        field: "display_product_type",
        editable: false,
        filter: "agTextColumnFilter",
        width: 210,
        sortable: true,
      },
      {
        headerName: "Reformulation Source",
        field: "reformulation_request_source",
        width: 200,
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
      },
      {
        headerName: "Date Created",
        field: "created_at",
        filter: "agTextColumnFilter",
        width: 200,
        sortable: true,
        cellRenderer: (params) => {
          return moment(params.data.created_at).format("YYYY-MM-DD");
        },
      },
    ],
    is_waiting_for_response: false,
    formula_info: {},
    permission: false,
    showInfo: false,
    qa_notes: "",
    loadingText: "",
    pin:"",
    user:{},
    modalOpen:false,
    full_name:""
    // formulaStatus: "Accept",
  });

  useEffect(() => {
    
    
    axios
      .get(baseURL + "/get-qc-formulas", getHeaders())
      .then((response) => {
          setqualityAnalytics({ ...qualityAnalytics, api_respose: response.data.data, loadingText: "", is_waiting_for_response: false });
        })
      .catch((err) => {
        handlingError();
      });

    let loginSucess = localStorage.getItem("login_success");
    if (loginSucess == 1) {
      let Role = getUserRole();
      let login = localStorage.getItem("login_success");
      if (login == 1) {
        axios
          .get(baseURL + "/users/PermissionDetails", getHeaders())
          .then((response) => {
            let permissionData = null;
            permissionData = simplifyModulePermissionData(response.data);
            permissionData = permissionData.filter((data) => {
              if (data.role_name === Role) {
                return data;
              }
            });

            permissionData.forEach((result) => {
              if (result.module_name == "Quality Assurance" && result.edit_permission === 1) {
                disabledPermission = false;
              }
            });
          })
          .catch((error) => {
            console.log("Fetching Display Grid | Error: ", error);
          });
      }
    }

    setqualityAnalytics({ ...qualityAnalytics, loadingText: "Loading Data", is_waiting_for_response: true });

    axios
      .get(baseURL + "/inventory/inventoryOptions", getHeaders())
      .then((response) => {
        customPrint("Start Formulation Modal", PRINT_CATEGORIES.RESPONSE, response);

        const inventory_options_json = response.data;
        converted_inventory_options_json = convertInventoryOptionsJSON(inventory_options_json);
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
      });

    document.title = config.qualtiyAssurance.title.display;
    focusFirstElement();
  }, []);

  const fetchSpecificForumlation = (params) => {
    setqualityAnalytics({ ...qualityAnalytics, loadingText: "Information Fetched", is_waiting_for_response: true });

    axios
      .get(baseURL + `/formulations-operator-new/info/${params.data.id}`, getHeaders())
      .then((response) => {
        axios
          .get(baseURL + "/sortInventory?formula_id=" + params.data.formula_id, getHeaders())
          .then((organism_response) => {
            response.data.organisms = organism_response.data.map((item)=> {
              item.final_amount_added = Math.round( item.final_amount_added * 10 ) / 10
              return item;
            });

            (response.data.prime_filler_amount_added !== null) && (response.data.prime_filler_amount_added = Math.round( response.data.prime_filler_amount_added * 10 ) / 10)
            setqualityAnalytics({ ...qualityAnalytics, formula_info: response.data, showInfo: true, qa_notes: "", loadingText: "Information Fetched", is_waiting_for_response: false });
          })
          .catch((err) => {
            handlingError();
          });
      })
      .catch((err) => {
        handlingError();
      });
  };
  const handlingError = () => {
    setqualityAnalytics({ ...qualityAnalytics, loadingText: "Something Goes wrong! Please try again later", is_waiting_for_response: true });
    /* setTimeout(() => {
      window.location.replace(baseURL);
    }, 1500); */
  };
  const onModalValueChange = (e, { name, value }) => {
    console.log(e);
  };
  const onModalValueChangeQcWeight = (e, { name, value }) => {
    console.log(e);
  };
  const onModalValueChangePill = (e, { name, value }) => {
    console.log(e);
  };

  const onSubmitClick = (data) => {       
    if (!qualityAnalytics.pin) {
      toast.error("Please fill pin.");
        return;
    }else if(Object.keys(qualityAnalytics.user).length === 0){
      toast.error("Please fill vaild pin.");
      return;
    }
    setqualityAnalytics({ ...qualityAnalytics, loadingText: "Updating Formula Data", is_waiting_for_response: true });
    var qa_value = {};
    qa_value.formulation_id = qualityAnalytics.formula_info.id;
    qa_value.qa_notes = qualityAnalytics.qa_notes;
    qa_value.qc_status = data == "Accept" ? 1 : 2;
    qa_value.qa_pin =qualityAnalytics.pin

    axios
      .post(baseURL + "/verify-qc-formula", qa_value, getHeaders())
      .then((res) => {
        if (res.data.success) {
          setqualityAnalytics({ ...qualityAnalytics, loadingText: "Formula Updated", is_waiting_for_response: false });
          toast.success(res.data.message);
          onOpenModalClick();
        } else {
          handlingError();
        }        
       
      })
      .catch((err) => console.log(err));
  };
  const updateQA_notes = (notes) => {
    setqualityAnalytics({ ...qualityAnalytics, qa_notes: notes.target.value });
  };
  const updatePin = (pin) => { 
    var pinValue  = pin.target.value 
    setqualityAnalytics({ ...qualityAnalytics,pin:pinValue })
    
    if (!pinValue ) {
      setqualityAnalytics({ ...qualityAnalytics, user:{},full_name:"",pin:pinValue  })
      toast.error("Please fill pin number.");
      return;
     }  
     if(pinValue.length === 4){      
      axios.post(baseURL+"/users/getUserByPin",{pin:pinValue}, getHeaders())
      .then(res=>{               
        if(res.data.success === true && res.data.user !== null){
            var user = res.data.user;                   
            setqualityAnalytics({ ...qualityAnalytics, pin:pinValue, user:user,full_name:user.full_name })
          
        }else{
          setqualityAnalytics({ ...qualityAnalytics, user:{},full_name:"",pin:pinValue })

        }        
      })
    }else{
      setqualityAnalytics({ ...qualityAnalytics, pin:pinValue, user:{},full_name:"" });      
    }
    
    
  };

  const onOpenModalClick = () => {
      setqualityAnalytics({...qualityAnalytics,modalOpen:true})
  }

 const onCloseModalClick = () => {
  setqualityAnalytics({...qualityAnalytics,modalOpen:false})
    setTimeout(() => {
      window.location.reload(true);
    }, 1500);
  }
  

  return (
    
    <>
      <br />
      <Modal
          closeIcon          
          open={ qualityAnalytics.modalOpen}
          onClose={onCloseModalClick}
          centered={false}
        >
          
          <Modal.Header className="modal_header">
            Summary
          </Modal.Header>
          <Modal.Description>
            <Segment>
              <Summary
                selected_row_value={qualityAnalytics.formula_info}
                pin={qualityAnalytics.pin}
              />
              {/* <LabelGeneration/> */}
            </Segment>
          </Modal.Description>

        </Modal>
      {console.log(qualityAnalytics)}
      {qualityAnalytics.is_waiting_for_response && <CustomLoader loading_text={qualityAnalytics.loadingText} />}
      <Message>
        <Header
          as="h2"
          textAlign="center"
        >
          Quality Assurance
        </Header>
      </Message>

      <Segment
        compact
        raised
        padded
        style={{ display: "block" }}
        className="transparent"
      >
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <div
                id="myGrid"
                ag-grid="gridOptions"
                style={{
                  height: "100%",
                  width: "100%",
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  rowHeight="30"
                  rowSelection={"single"}
                  editType={qualityAnalytics.editType}
                  floatingFilter={true}
                  onRowClicked={fetchSpecificForumlation}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={(params) => {
                    return "[" + params.value.toLocaleString() + "]";
                  }}
                  columnDefs={qualityAnalytics.agGridReactColumn}
                  rowData={qualityAnalytics.api_respose}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          {qualityAnalytics.showInfo && (
            <>
              <Segment>
                <Form>
                  <Grid columns="equal">
                    {/* Summary */}
                    <SummaryInfo
                      sample_id={qualityAnalytics.formula_info.sample_id}
                      formula_id={qualityAnalytics.formula_info.formula_id}
                      number_of_months={qualityAnalytics.formula_info.number_of_months}
                      date_made={qualityAnalytics.formula_info.date_made}
                      date_created={qualityAnalytics.formula_info.date_created}
                      formulation_factor={qualityAnalytics.formula_info.formulation_factor}
                      pin={qualityAnalytics.pin}
                      user_name={qualityAnalytics.full_name}
                      page_type = "qa_page"
                      onChange={updatePin}
                      onModalValueChange_Date={""}
                    />
                    <Grid.Row />
                    {qualityAnalytics.formula_info.prime_filler_inventory_id !== null && (
                      <PrimeInfo
                        prime_flourish_amount_added={qualityAnalytics.formula_info.prime_flourish_amount_added || ""}
                        prime_filler_inventory_id={qualityAnalytics.formula_info.prime_filler_inventory_id || ""}
                        converted_inventory_options_json={qualityAnalytics.formula_info.inventory_barcode[0].organism_barcode}
                        prime_filler_amount_added={qualityAnalytics.formula_info.prime_filler_amount_added || ""}
                        prime_total_amount_added={qualityAnalytics.formula_info.prime_total_amount_added || ""}
                        prime_fill_qc_weight={qualityAnalytics.formula_info.prime_fill_qc_weight || ""}
                        prime_capsule_size={qualityAnalytics.formula_info.prime_capsule_size || ""}
                        prime_total_capsules_made={qualityAnalytics.formula_info.prime_total_capsules_made || ""}
                        prime_powder_remaining={qualityAnalytics.formula_info.prime_powder_remaining || ""}
                        onModalValueChange={onModalValueChange}
                        permission={qualityAnalytics.permission}
                      />
                    )}
                    <Grid.Row />

                    {/* Flourish */}
                    <FlourishInfo
                      flourish_fill_qc_weight={""}
                      filled_tube={qualityAnalytics.formula_info.filled_tube || ""}
                      // filled_tube={""}
                      flourish_powder_remaining={qualityAnalytics.formula_info.flourish_powder_remaining || 0}
                      flourish_total_capsules_made={qualityAnalytics.formula_info.flourish_total_capsules_made || 0}
                      flourish_capsule_size={qualityAnalytics.formula_info.flourish_capsule_size || "powder"}
                      organisms={qualityAnalytics.formula_info.organisms}
                      admin_notes={qualityAnalytics.formula_info.admin_notes || ""}
                      operator_notes={qualityAnalytics.formula_info.operator_notes || ""}
                      total_final_amount_added={qualityAnalytics.formula_info.total_final_amount_added || 0}
                      //functions
                      onModalValueChange={onModalValueChange}
                      quality_assurance_notes={""}
                      onModalValueChangeQcWeight={onModalValueChangeQcWeight}
                      onModalValueChangePill={onModalValueChangePill}
                      permission={qualityAnalytics.permission}
                      onChange={updateQA_notes}
                      qa_notes={qualityAnalytics.qa_notes}
                    />
                    <Grid.Row />
                  </Grid>
                </Form>
              </Segment>

              <br />
              <br />
              <Grid.Row>
                <Grid.Column>
                  <Grid textAlign="center">
                    <Button.Group>
                      <Popup
                        trigger={
                          <Button
                            content={"Accept"}
                            icon="check"
                            labelPosition="left"
                            positive
                            disabled={disabledPermission}
                            onClick={() => onSubmitClick("Accept")}
                          />
                        }
                        content="Accept Formula."
                      />

                      <Button.Or />
                      <Popup
                        trigger={
                          <Button
                            content={"Reject"}
                            icon="ban"
                            labelPosition="right"
                            negative
                            onClick={() => {
                               if(qualityAnalytics.qa_notes !== ""){
                                 let r = window.confirm("Are you sure you want to reject?");
                                 if (r == true ) {
                                   onSubmitClick("Reject");
                                  }
                                }else{
                                  toast.error("Please Fill QA Notes section");
                                }
                                
                            }}
                          />
                        }
                        content="Reject Formula"
                      />
                    </Button.Group>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Segment>
    </>
  );
};
export default QuantityAssurance;
