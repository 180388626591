import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders,getSessionStoredJsonItem } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input } from "semantic-ui-react";

class OperatorSignIn extends Component {

    componentDidMount(){
      this.getCurrentStep('operator_sign_in');
    }
    
    getCurrentStep=(step_name)=>{
      let req = {
        formula_id : this.props.selected_row_value.formula_id,
        step_name : step_name
      }
      axios.post(baseURL+"/get-current-step",req, getHeaders())
        .then(res=>{
          let result = res.data; 
          if(result.success === true && result.current_step !== null){
            let current_step = result.current_step;
            current_step.step_json = JSON.parse(current_step.step_json);             
            this.setState({step_current : current_step});
            this.onInputChange(null,{name:"pin","value":current_step.performed_by});          
          }
      })
    }
    state = { pin: "",user:"" };
    onInputChange = (e, { name, value }) => {       
              
        this.setState({ [name]: value });
        if (!value) {
          toast.error("Please fill pin number.");
          return;
        }   
        axios.post(baseURL+"/users/getUserByPin",{pin:value}, getHeaders())
        .then(res=>{               
          if(res.data.success === true && res.data.user !== null){
              let user = res.data.user;
              this.setState({user:user}); 
              this.props.onNextStep();
            //this.setState({user_full_name:user.full_name}); 
          }else{
            this.setState({user:""});
          }
        })
    };

    handleSubmit = ()=>{
      const { pin } = this.state;
        if (!pin) {
            toast.error("Please fill pin.");
            return;
        }else if(Object.keys(this.state.user).length == 0){
          toast.error("Please fill vaild pin.");
          return;
        }
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:2,
            step_json:"",
            step_name:'operator_sign_in',
            step_next:'production_line_checkout',
            performed_by:this.state.user.pin
          }
          axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onNextStep();
          }else{
            toast.error("Step has not saved");
          }
        })
    }
    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('mmr_approval');

    };

    handlePauseClick = ()=>{
    this.props.onNextClick('pause');
  }
  handleQuitClick = ()=>{
    this.props.onNextClick('quit');
  }

  handleNextClick = () => {     
        // Call the function passed from the parent
      this.props.onNextClick('production_line_checkout');        
  };


    render(){    
        console.log(this.props.step_prev);    
        console.log(this.props.step_prev === "operator_sign_in");
        console.log(this.props.step_prev === '');
        return (
            <>
                
            <div className='cs-box'>
                <div className='cs-body'>                      
                    <h3>Operator Sign In</h3>
                    <div className='mt-5 text-center'>
                    <div className='d-inline-flex align-items-center mb-2'>
                        <div><h4 className='m-0 pe-4'>PIN</h4></div>
                        <div className="ui input">
                        <Form.Field required fluid="true">                    
                          <Input
                            id="pin"
                            name="pin"
                            onChange={this.onInputChange}
                            value={this.state.pin}
                            placeholder="Enter Your PIN"
                            type="password"
                            className="full_opacity"
                            autoComplete="new-password"
                          />
                        </Form.Field>
                        {/* <input type='text' className='form-control'/>     */}
                      </div>
                    </div>
                        <h4>Operator(s): <span className='theme-text'><u>{this.state.user.full_name}</u></span></h4>
                    </div>
                </div>
                <div className='cs-footer text-center'>
                    <hr/>
                    <div className='text-center mt-2'>
                        <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                        <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                        { this.props.step_prev === '' && (<button type='button' onClick={this.handleSubmit}  className='theme-btn ms-2' >Next</button>)}
                        { this.props.step_prev == "operator_sign_in" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
                    </div>
                </div>
            </div>
            </>
          )
    }
}

export default OperatorSignIn