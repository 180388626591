import React, { Component } from 'react'
import { Form, Input, TextArea } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

class FlourishPillCount extends Component {
    state = {performed_by_pin:"",confirmed_by_pin:"",performed_by:"",performed_by_name:"",confirmed_by:"",confirmed_by_name:""}
    
    componentDidMount(){                
        this.getCurrentStep('flourish_pill_count');
        this.setState({total_amount : (this.props.selected_row_value.number_of_months*30)+10});
    }
    getCurrentStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name : step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;
            if(result.success === true && result.current_step !== null){
                let current_step = result.current_step;
                let step_json = JSON.parse(current_step.step_json);    
                console.log(step_json);         
                this.setState({step_current : current_step});
                this.onInputChange(null,{name:"performed_by_pin","value":current_step.performed_by});
                this.onInputChange(null,{name:"confirmed_by_pin","value":current_step.confirmed_by});
                this.onInputChange(null,{name:"measured","value":step_json.measured});
                this.onInputChange(null,{name:"primary_operator","value":step_json.primary_operator});
                this.onInputChange(null,{name:"secondary_signoff","value":step_json.secondary_signoff});
                this.onInputChange(null,{name:"operator_notes","value":step_json.operator_notes});
            }
        })
    } 

    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('pilling_of_mixed_flourish_ingredient');        
    };
    handleNextClick = () => {     
        // Call the function passed from the parent
        this.props.onNextClick('flourish_pill_weight');
    };
    handlePauseClick = ()=>{
        this.props.onNextClick('pause');
    }
    handleQuitClick = ()=>{
        this.props.onNextClick('quit');
    }
    onInputChange = (e, { name, value }) => {      
        console.log(name); 
        switch (name) {         
            case 'performed_by_pin':
                if( value.length === 4){
                    this.getPerformUser(value,'performer');
                }else{
                    this.setState({performed_by:"",performed_by_name:""});
                }
                break;
            case 'confirmed_by_pin':
                if( value.length === 4){
                    this.getPerformUser(value,'confirmer');
                }else{
                    this.setState({confirmed_by:"",confirmed_by_name:""});
                }
                break;
            case 'measured':               
                this.state.yield = ((value/this.state.total_amount)*100).toFixed(2);    
                break;
        }
            
      this.setState({ [name]: value });
      
    };
    getPerformUser = (pin,user_type)=>{
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }   
          axios.post(baseURL+"/users/getUserByPin",{pin:pin}, getHeaders())
          .then(res=>{               
            if(res.data.success === true && res.data.user !== null){
                let user = res.data.user;
                if(user_type === 'performer'){                   
                    this.setState({performed_by:user.id,performed_by_name:user.full_name});
                }else{
                    this.setState({confirmed_by:user.id,confirmed_by_name:user.full_name});
                }
                 
              //this.setState({user_full_name:user.full_name}); 
            }
        })
    }

    handleSubmit = ()=>{        
        if (!this.state.performed_by_name) {
            toast.error("Please fill vaild pin.");
            return;
        }
        if (!this.state.confirmed_by_name) {
            toast.error("Please fill vaild pin.");
            return;
        }
        let step_json = {
            total_amount:this.state.total_amount,
            operator_notes:this.state.operator_notes,
            measured:this.state.measured,
            yield:this.state.yield,
            primary_operator:this.state.primary_operator,
            secondary_signoff:this.state.secondary_signoff
        }
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:18,
            step_json:JSON.stringify(step_json),
            step_name:'flourish_pill_count',
            step_next:'flourish_pill_weight',
            performed_by:this.state.performed_by_pin,
            confirmed_by:this.state.confirmed_by_pin
        }       
        axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onNextStep();
          }else{
            toast.error("Step has not saved");
          }
        })
    }
    render() {
        console.log(this.props);
        return (
            <>
            <Form onSubmit={this.handleSubmit}>    
                <div className='cs-box'>
                    <div className='cs-body'>
                    <h3>Flourish Pill Count</h3>
                        <div className='mt-2'>
                            <ul className='cs-list'>
                                <li>The expected final yield is the number of capsules to be created for pilled formulations and of total combined weight for powder formulations.</li>
                            </ul>
                            <p>Prime capsules<br />
                                <div className='ps-3 font-14'>
                                <strong>Manufacture 40 pills = 10 for retain + 30 for customer <br />
                                    Acceptable Yield range: 82.5% - 100</strong>
                                    </div>
                            </p>

                        </div>
                        
                        <div className='mt-5'>
                        <h4>Capsule Count</h4>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th>Target Capsule Count</th>
                                            <th>Measured</th>
                                            <th>Yield</th>
                                            <th>Primary Operator</th>
                                            <th>Secondary Signoff</th>
                                        </tr>
                                        <tr>
                                            <th>{this.state.total_amount}</th>
                                            <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                        id={`measured`}
                                                        name="measured"
                                                        onChange={this.onInputChange}
                                                        value={this.state.measured?this.state.measured:""} 
                                                        type="text"
                                                        className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                            </td>
                                            <td className='text-nowrap text-green'>{this.state.yield}</td>
                                            <td>
                                                <Form.Field required fluid="true">                    
                                                    <Input
                                                    id={`primary_operator`}
                                                    name="primary_operator"
                                                    onChange={this.onInputChange}
                                                    value={this.state.primary_operator?this.state.primary_operator:""} 
                                                    type="text"
                                                    className="full_opacity form-control w-100 text-center"
                                                    />
                                                </Form.Field> 
                                            </td>
                                            <td>
                                                    <Form.Field required fluid="true">                    
                                                        <Input
                                                        id={`secondary_signoff`}
                                                        name="secondary_signoff"
                                                        onChange={this.onInputChange}
                                                        type="text"
                                                        value={this.state.secondary_signoff?this.state.secondary_signoff:""} 
                                                        className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0'>Operator Notes:</h4></div>
                            <div>
                                <Form.Field required fluid="true">                    
                                    <TextArea
                                    id="operator_notes"
                                    name="operator_notes"
                                    onChange={this.onInputChange}
                                    value={this.state.operator_notes}                                        
                                    placeholder="Operator Notes"
                                    className="notes white-textarea text-black"
                                    />
                                </Form.Field>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0 text-center mb-1'>Statement Of Deviation Requirements:</h4></div>
                            <div><h5 className='m-0 text-center fw-500'>If any process yields fall outside of stated range, conditional step "3C. Process Deviation" is to be conducted.</h5></div>
                        </div>
                        <div className='text-center mt-5'>
                        <div className='d-inline-flex'>
                            <div className='d-flex align-items-center'>
                                <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                <div className='m-0 pe-1'>
                                    <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                <div className='ui input'>
                                    <Form.Field required fluid="true">                    
                                        <Input
                                        id="performed_by_pin"
                                        name="performed_by_pin"
                                        onChange={this.onInputChange}
                                        value={this.state.performed_by_pin}
                                        placeholder="Enter Your PIN"
                                        type="password"
                                        className="full_opacity"
                                        autoComplete="new-password"
                                        />
                                    </Form.Field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center mt-2 mb-5'>
                        <div className='d-inline-flex'>
                            <div className='d-flex align-items-center'>
                                <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                <div className='m-0 pe-1'>
                                    <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                <div className='ui input'>
                                    <Form.Field required fluid="true">                    
                                        <Input
                                        id="confirmed_by_pin"
                                        name="confirmed_by_pin"
                                        onChange={this.onInputChange}
                                        value={this.state.confirmed_by_pin}
                                        placeholder="Enter Your PIN"
                                        type="password"
                                        className="full_opacity"
                                        autoComplete="new-password"
                                        />
                                    </Form.Field>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='cs-footer text-center'>
                        <hr />
                        <div className='text-center mt-2'>
                            <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                            <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                            <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                            { this.props.step_name === 'pilling_of_mixed_flourish_ingredient' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                            { this.props.step_name !== "pilling_of_mixed_flourish_ingredient" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}                        
                        </div>
                    </div>
                </div>
            </Form>
            </>
        )
    }
}
export default FlourishPillCount;